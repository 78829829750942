import Link from 'next/link'

import ServiceNavigationMenu from './pageBuilderBlocks/ServiceNavigationMenu'
import FeaturedDeals from './pageBuilderBlocks/FeaturedDeals'
import SectionDivider from './pageBuilderBlocks/SectionDivider'
import LatestNews from './pageBuilderBlocks/LatestNews'
import InsightsGrid from './pageBuilderBlocks/InsightsGrid'
import ServiceNavigationList from './pageBuilderBlocks/ServiceNavigationList'
import SectorNavigationList from './pageBuilderBlocks/SectorNavigationList'
import Image from './pageBuilderBlocks/Image'
import SingleMedia from './pageBuilderBlocks/SingleMedia'
import VideoGrid from './pageBuilderBlocks/VideoGrid'
import ImageDivider from './pageBuilderBlocks/ImageDivider'
import SectionHeader from './pageBuilderBlocks/SectionHeader'
import TwoColumnText from './pageBuilderBlocks/TwoColumnText'
import SingleColumnText from './pageBuilderBlocks/SingleColumnText'
import DownloadsList from './pageBuilderBlocks/DownloadsList'
import AamPerformanceChart from './pageBuilderBlocks/AamPerformanceChart'

import LockUpBasic from './pageBuilderBlocks/LockUpBasic'
import LockUpLinkList from './pageBuilderBlocks/LockUpLinkList'
import LockUpOpenPositions from './pageBuilderBlocks/LockUpOpenPositions'
import LockUpFullScreen from './pageBuilderBlocks/LockUpFullScreen'
import People from './pageBuilderBlocks/People'
import CollapsibleList from './pageBuilderBlocks/CollapsibleList'
import BlockLinkList from './pageBuilderBlocks/BlockLinkList'

import CustomContentList from './pageBuilderBlocks/CustomContentList'
import Morningstar from './pageBuilderBlocks/Morningstar'
import Newsletter from './pageBuilderBlocks/Newsletter'

import KeyFigures from './pageBuilderBlocks/KeyFigures'
import FundPerformanceApp from '../fundPerformance/App/FundPerformanceApp'

interface PageBuilderProps {
	contentBlocks: Array<any>
	dynamicData?: any
}

const PageBuilder = (props: PageBuilderProps) => {

	const { contentBlocks, dynamicData } = props

	if (!contentBlocks || contentBlocks.length === 0) {
		return null
	}

	// console.log('PageBuilder contentBlocks: ', contentBlocks)

	return (
		<div className='page-builder'>
			{contentBlocks.map((block: any) => {

				switch (block.typeHandle) {
					case 'serviceNavigationMenu':

						return <ServiceNavigationMenu key={block.id} block={block} />

					case 'sectionDivider':

						return <SectionDivider key={block.id} />

					case 'featuredDeals':

						return <FeaturedDeals key={block.id} block={block} deals={dynamicData?.featuredDeals} />

					case 'latestNews':

						// TODO - Check implementation. Add heading and link as own props
						return <LatestNews key={block.id} {...block} news={dynamicData?.news} />

					case 'insights':

						return <InsightsGrid key={block.id} block={block} />

					case 'image':

						return <Image key={block.id} block={block} />

					case 'video':

						if (block.video?.length > 1) {
							return <VideoGrid
								key={block.id}
								videos={block.video?.length > 0 ? block.video : null}
							/>
						} else if (block.video?.length == 1) {
							return <SingleMedia
								key={block.id}
								media={block.video?.length > 0 ? block.video[0] : null}
								caption={block.caption}
							/>
						}

					case 'imageDivider':

						return <ImageDivider key={block.id} block={block} />

					case 'serviceNavigationList':

						return <ServiceNavigationList key={block.id} block={block} />

					case 'sectorNavigationList':

						return <SectorNavigationList key={block.id} block={block} />
					case 'sectionHeader':

						return <SectionHeader key={block.id} block={block} />

					case 'lockUpBasic':

						return <LockUpBasic key={block.id} block={block} />

					case 'lockUpLinkList':

						return <LockUpLinkList key={block.id} block={block} />

					case 'lockUpOpenPositions':

						return <LockUpOpenPositions key={block.id} block={block} />

					case 'lockUpFullScreen':

						return <LockUpFullScreen key={block.id} block={block} />

					case 'twoColumnText':

						return <TwoColumnText key={block.id} block={block} />

					case 'singleColumnText':

						return <SingleColumnText key={block.id} block={block} />

					case 'people':

						return <People key={block.id} block={block} />

					case 'collapsibleList':

						return <CollapsibleList key={block.id} block={block} />

					case 'customContentList':

						return <CustomContentList key={block.id} block={block} />

					case 'aamPerformanceChart':

						return <FundPerformanceApp key={block.id} block={block} />
						// return <AamPerformanceChart key={block.id} block={block} />

					case 'keyFigures':

						return <KeyFigures key={block.id} block={block} />

					case 'morningstar':

						return <Morningstar key={block.id} block={block} />

					case 'downloadsList':

						return <DownloadsList key={block.id} block={block} />

					case 'linkList':

						return <BlockLinkList key={block.id} block={block} />

					case 'newsletter':

						return <Newsletter key={block.id} block={block} />

					default:
						return null
				}

			})}
		</div>
	)
}
export default PageBuilder
