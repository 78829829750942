import Link from 'next/link'

interface ServiceNavigationMenuProps {
	block: any
}


const ServiceNavigationMenu = (props: ServiceNavigationMenuProps) => {

	const { block } = props

	if (!block.selectedServices || block.selectedServices.length === 0) {
		return null
	}

	return (
		<div key={block.id} className="full-service-navigation">
			<div className="full-service-navigation__wrapper">
				{block.selectedServices.map((service: any) => {

					return (
						<div key={service.id} className="full-service-navigation__block">
							<div className="full-service-navigation__block-inner">

								<div className="full-service-navigation__block-header">
									<h3>{service.title}</h3>
									<span dangerouslySetInnerHTML={{__html: service.shortDescription}} />
									<a href={`/${service.uri}`}>Read more</a>
								</div>

								{service.children && service.children.length > 0 ? (
									<ul className='link-list'>
										{service.children.map((innerBlock: any) => {
											return (
												<li key={innerBlock.id} className='link-list__item'>
													<Link href={`/${innerBlock.uri}`} className='link-list__link'>
														<span>{innerBlock.title}</span>
														<span>→</span>
													</Link>
												</li>
											)
										})}
									</ul>
								) : null}

							</div>
						</div>
					)
				})}
			</div>
		</div>
	)

}
export default ServiceNavigationMenu
