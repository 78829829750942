import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import styled from '@emotion/styled';
import Spinner from '../../shared/components/Spinner';
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles';
import { ClassContext } from '../FundPerformanceContent';
import { roundToDecimals } from '../../helpers/number-helper';

const ProgressBar = ({
	value, maxValue, units, progressColor, valueOfScaleStep,
}) => {
	const scaleDivisionsAmount = Math.floor(maxValue / valueOfScaleStep) + 1;
	const isMaxValueWhole = (maxValue % valueOfScaleStep) === 0;
	const divisionsValues = Array(scaleDivisionsAmount).fill()
		.map((el, index) => index * valueOfScaleStep);

	const progressValue = (value / maxValue) * 100;
	const calcLeftDisplacement = el => ((el / maxValue) * 100);

	return (
		<Container>
			<Bar>
				{divisionsValues.map((el, index) => {
					const isLeftEdgeLabel = el === 0;
					const isRightEdgeLabel = isMaxValueWhole &&
						index === (divisionsValues.length - 1);
					const isEdgeDivider = isLeftEdgeLabel || isRightEdgeLabel;
					return (
						<Fragment key={`progressBarScaleKey-${index + 1}`}>
							<ScaleElement
								isEdgeDivider={isEdgeDivider}
								leftDisplacement={calcLeftDisplacement(el)}
							/>
							<Label
								leftDisplacement={calcLeftDisplacement(el)}
								isLeftEdgeLabel={isLeftEdgeLabel}
								isRightEdgeLabel={isRightEdgeLabel}
							>
								{`${el}${units}`}
							</Label>
						</Fragment>
					);
				})}
				<Progress
					width={progressValue}
					progressColor={progressColor}
				>
					{progressValue > 5 ? `${value}${units}` : ''}
				</Progress>
			</Bar>
		</Container>
	);
};

ProgressBar.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	maxValue: PropTypes.number.isRequired,
	progressColor: PropTypes.string.isRequired,
	units: PropTypes.string.isRequired,
	valueOfScaleStep: PropTypes.number,
};

ProgressBar.defaultProps = {
	valueOfScaleStep: 1,
};

const useNavpositions = ISIN => useQuery(['otherMetrics', ISIN], async () => {
	const { data } = await axios.get(`/api/funds/nav-positions`, {
		params: {
			key: 'ISIN',
			value: ISIN,
			sort: 'rDate:DESC',
			limit: 1
		}
	});
	return data[0];
});

const OtherMetrics = () => {
	const { ISIN } = useContext(ClassContext);
	const {
		data, isLoading, isSuccess, isError, error,
	} = useNavpositions(ISIN);

	if (isError) {
		return (
			<span>Error: {error.message}</span>
		);
	}

	return (
		<SectionWrapper>
			<SectionTitle>
				Other metrics
			</SectionTitle>
			{isLoading && <Spinner />}
			{isSuccess && (
				<Fragment>
					<BarTitle>Duration</BarTitle>
					<ProgressBar
						value={roundToDecimals(data.duration, 1)}
						maxValue={3}
						units="Y"
						progressColor="#395175"
					/>
					<BarTitle>Credit maturity</BarTitle>
					<ProgressBar
						value={roundToDecimals(data.tenor, 1)}
						maxValue={5}
						units="Y"
						progressColor="#ED7132"
					/>
					<BarTitle>Yield</BarTitle>
					<ProgressBar
						value={roundToDecimals(data.yield, 1)}
						maxValue={14}
						units="%"
						progressColor="#7793BB"
						valueOfScaleStep={2}
					/>
				</Fragment>
			)}
		</SectionWrapper>
	);
};

export default OtherMetrics;


const Container = styled('div')`
  height: 35px;
  margin-bottom: 16px;
`;

const Bar = styled('div')`
  background-color: #F3F3F3;
  height: 20px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 15px;
`;

const BarTitle = styled('div')`
  margin-bottom: 8px;
  font-weight: bold;
`;

const Progress = styled('div')`
  display: flex;
  justify-content: center;
  border-radius: 4px 0 0 4px;
  height: 20px;
  width: ${props => props.width}%;
  background-color: ${props => props.progressColor};
  color: white;
  z-index: 2;
  position: absolute;
  font-size: 12px;
`;

const ScaleElement = styled('div')`
  ${props => (props.isEdgeDivider ? 'display: none' : '')}
  height: 100%;
  width: 1px;
  z-index: 1;
  background-color: #E0E0E0;
  position: absolute;
  left: ${props => props.leftDisplacement}%;
`;

const getLabelPosition = ({
	isLeftEdgeLabel,
	isRightEdgeLabel,
	leftDisplacement,
}) => {
	if (isLeftEdgeLabel) {
		return `
      left: ${leftDisplacement}%;
      justify-content: flex-start;
    `;
	}
	if (isRightEdgeLabel) {
		return `
      left: calc(${leftDisplacement}% - 60px);
      justify-content: flex-end;
    `;
	}
	return `
    left: calc(${leftDisplacement}% - 30px);
    justify-content: center;
  `;
};

const Label = styled('div')`
  position: absolute;
  top: 20px;
  display: flex;
  width: 60px;
  font-size: 12px;
  ${props => getLabelPosition(props)};
`;
