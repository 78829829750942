import styled from '@emotion/styled';
import { FundColor } from '../../../shared/styles';

export const Button = styled('button')` 
  width: 100%;
  background: ${FundColor};
  color: white;
  border: 1px solid ${FundColor};
  padding: 12px 100px;
  font-size: 18px;
  line-height: 24px;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

export const OutlineButton = styled('button')`
  border: none;
  background-color: transparent;
  color: ${FundColor};
  font-weight: 500;
  padding: 0;
  display: flex;
  align-items: center;
`;
