/* eslint-disable */
import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Spinner from '../../shared/components/Spinner';
import Table from '../../shared/components/Table';
import { DownloadIcon } from '../../shared/icons';
import styled from '@emotion/styled';
import { FundColor } from '../../shared/styles';
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles';
import { ClassContext } from '../FundPerformanceContent';

const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    border: none;
    background-color: transparent;
    color: ${FundColor};
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover,
    &:active,
    &:visited {
      color: ${FundColor};
      text-decoration: none;
    }
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    fill: ${FundColor};
  }
`;

const addPercentSymbol = value =>
  (value ? `${Number(value).toFixed(2)}%` : '-');

const columns = [{
  title: 'Year',
  key: 'year',
  index: 'year',
}, {
  title: 'Jan',
  key: 0,
  index: 0,
  render: addPercentSymbol,
}, {
  title: 'Feb',
  key: 1,
  index: 1,
  render: addPercentSymbol,
}, {
  title: 'Mar',
  key: 2,
  index: 2,
  render: addPercentSymbol,
}, {
  title: 'Apr',
  key: 3,
  index: 3,
  render: addPercentSymbol,
}, {
  title: 'May',
  key: 4,
  index: 4,
  render: addPercentSymbol,
}, {
  title: 'Jun',
  key: 5,
  index: 5,
  render: addPercentSymbol,
}, {
  title: 'Jul',
  key: 6,
  index: 6,
  render: addPercentSymbol,
}, {
  title: 'Aug',
  key: 7,
  index: 7,
  render: addPercentSymbol,
}, {
  title: 'Sep',
  key: 8,
  index: 8,
  render: addPercentSymbol,
}, {
  title: 'Oct',
  key: 9,
  index: 9,
  render: addPercentSymbol,
}, {
  title: 'Nov',
  key: 10,
  index: 10,
  render: addPercentSymbol,
}, {
  title: 'Dec',
  key: 11,
  index: 11,
  render: addPercentSymbol,
}, {
  title: 'YTD',
  key: 'ytd',
  index: 'ytd',
  render: addPercentSymbol,
}];

const useNavpositions = ISIN => useQuery(['monthReturn', ISIN], async () => {
	const { data } = await axios.get(`/api/funds/nav-positions/monthly-return`, {
		params: {
			isin: ISIN
		}
	});

  return data;
});

const MonthlyReturn = () => {
  const { ISIN } = useContext(ClassContext);
  const {
    data, isError, isSuccess, isLoading, error,
  } = useNavpositions(ISIN);

  if (isError) {
    return (
      <span>Error: {error.message}</span>
    );
  }

  //TODO: Figure out how the download should work. Doesn't work in prod
  return (
    <SectionWrapper>
      <HeaderWrapper>
        <SectionTitle>Monthly Return</SectionTitle>
        {/*<a*/}
        {/*  href={`/aam/${window.lang}/department/arctic-funds-download/month-return?ISIN=${ISIN}`}*/}
        {/*  target="_blank"*/}
        {/*  rel="noreferrer"*/}
        {/*>*/}
        {/*  <DownloadIcon />*/}
        {/*  Excel*/}
        {/*</a>*/}
      </HeaderWrapper>
      {isLoading && <Spinner />}
      {isSuccess && (
        <Table rows={data} columns={columns} isScrollable isFixedFirstColumn />
      )}
    </SectionWrapper>
  );
};

export default MonthlyReturn;
