import styled from '@emotion/styled';

export const TooltipContent = styled('div')`
  pointer-events: none;

  * {
    color: white !important;
    margin-bottom: 0 !important;
  }
`;

export const TooltipValue = styled('p')`
  margin: 0;
`;
