import ResponsiveImage from "../../common/ResponsiveImage"

interface ImageDividerProps {
	block: any
}

const ImageDivider = (props: ImageDividerProps) => {

	const { block } = props

	return (
		<ResponsiveImage
			image={ block.image && block.image.length > 0 ? block.image[0] : null }
			containerClass={`block-image-divider block-image-divider--${block.removeSpaceAbove ? 'no-space-above' : 'standard'}`}
			cleanImplementation={true}
		/>
	)

}
export default ImageDivider
