import React, { useContext } from 'react';
import { transparentize } from 'polished';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Spinner from '../../../shared/components/Spinner';
import { GreenColor, RedColor } from '../../../shared/styles';
import Growth from './Growth';
// import { ClassContext } from '../../FundPerformanceContent';

const useNavPositions = isin => useQuery(['panels', isin], async () => {
  
	const { data } = await axios.get(`/api/funds/nav-positions`, {
		params: {
			key: 'ISIN',
			value: isin,
			sort: 'rDate:DESC',
			limit: 1
		}
	});
  if (data[0]) {
    const date = new window
      .Date(new window.Date(data[0].rDate).getFullYear() - 1, 11, 31);

	  const { data: lastYear } = await axios.get(`/api/funds/nav-positions`, {
		  params: {
			  key: 'ISIN',
			  value: isin,
			  sort: 'rDate:DESC',
			  date: date,
			  limit: 1
		  }
	  });

    return {
      ...data[0],
      yearToDate: lastYear && (((data[0].NAV / lastYear[0].NAV) - 1) * 100)
        .toFixed(2),
    };
  }

  return {};
});

const Interest = ({ data }) => {
  return (
    <>
      <div>
        <div className="label">Current yield p.a.</div>
        <div className="growth">
          <Growth percent={data?.yield} />        
        </div>
      </div>
      <div>
        <div className="label">Year to date</div>
        <div className="growth">
          <Growth percent={data?.yearToDate} />
        </div>
      </div>
      <div>
        <div className="label">Last 12 months</div>
        <div className="growth">
          <Growth percent={data?.pa12M} />
        </div>
      </div>
      <div>
        <div className="label">Last 3 years annualised</div>
        <div className="growth">
          <Growth percent={data?.pa36M} />
        </div>
      </div>
    </>
  )
}

const Stock = ({ data }) => {
  return (
    <>
      <div>
        <div className="label">Return YTD</div>
        <div className="growth">
          <Growth percent={data?.yearToDate} />        
        </div>
      </div>
      <div>
        <div className="label">Last 12 months</div>
        <div className="growth">
          <Growth percent={data?.pa12M} />
        </div>
      </div>
      <div>
        <div className="label">Last 3 years annualised</div>
        <div className="growth">
          <Growth percent={data?.pa36M} />
        </div>
      </div>
      <div>
        <div className="label">Last 5 years annualised</div>
        <div className="growth">
          <Growth percent={data?.pa60M} />
        </div>
      </div>
    </>
  )
}

const Panels = ( props ) => {
  const { ISIN, locale, linkToPage, linkLabel } = props;
  // const { ISIN, locale } = useContext(ClassContext);
  const { data, status, error } = useNavPositions(ISIN);

  if (status === 'loading') return <Spinner />;
  if (status === 'error') {
    return (
      <span>Error: {error.message}</span>
    );
  }

  if (!data || Object.keys(data).length === 0) return null;

  const date = new window.Date(data.rDate)
    .toLocaleDateString(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  let numbers;

  if (data.yield) {
    numbers = <Interest data={data} />
  } else {
    numbers = <Stock data={data} />
  }
  return (
    <div className="key-numbers">
      <div className="key-numbers__grid">
        {numbers}
      </div>
      <div className="key-figures__bottom-container">
        <div className="date-updated">Latest update <span className="date">{date || '-'}</span></div>
        {linkToPage && linkToPage[0] &&
          <a href={`/${linkToPage[0].uri}`} className='button button--on-dark'>→ {linkLabel ? linkLabel : "View more performance"}</a>
        }
      </div>
    </div>
  );
};

export default Panels;
