import { useState } from 'react';

export default function useCollapse(defaultValue = true) {
  const [isCollapsed, setIsCollapsed] = useState(defaultValue);
  const toggleCollapse = () => setIsCollapsed(prev => !prev);
  return {
    isCollapsed,
    toggleCollapse,
  };
}
