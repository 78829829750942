import React from 'react'
import PropTypes from 'prop-types'
import { StyledTable } from './styled/Table'

const VerticalTable = ({ columns, rows }) => {
  const renderData = (index, render) =>
    rows.map((row, i) =>
      render ? (
        <td key={`${row.id}-${i}`} id={`td-${row.id}-${i}`}>
          {render(row[index])}
        </td>
      ) : (
        <td key={`${row.id}-${i}`} id={`td-${row.id}-${i}`}>
          {row[index] || '-'}
        </td>
      )
    );

  return (
    <StyledTable isVertical>
      <tbody>
      {columns.map((item, index) => (
        <tr key={item.key + '-' + index}>
          <td key={item.title + '-' + index}>{item.title}</td>
          {renderData(item.index, item.render)}
        </tr>
      ))}
      </tbody>
    </StyledTable>
  )
}

VerticalTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.string,
    title: PropTypes.string,
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default VerticalTable
