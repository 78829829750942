/* eslint-disable */
import React from 'react';
import styled from '@emotion/styled';
import { FundColor } from '../../shared/styles';

const StyledIcon = styled.svg`
  width: 24px;
  height: 24px;
  transition: 0.3s;
  fill: ${FundColor};
  
  ${({ right }) => right && 'transform: rotate(180deg);'}
`;

export const ChevronLeftIcon = props => (
  <StyledIcon viewBox="0 0 24 24" {...props}>
    <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
  </StyledIcon>
);

export const VectorUp = props => (
  <svg width="20" height="19" viewBox="0 0 20 19" {...props}>
    <path d="M7.18626 0.833313V3.16665H15.4186L0.940186 16.6883L2.70158 18.3333L17.18 4.81165V12.5H19.6784V0.833313H7.18626Z" />
  </svg>
);

export const VectorDown = props => (
  <svg width="20" height="19" viewBox="0 0 20 19" {...props}>
    <path d="M19.5275 6.50002H17.0291V14.1884L2.5507 0.666687L0.789307 2.31169L15.2677 15.8334H7.03538V18.1667H19.5275V6.50002Z" />
  </svg>
);

export const ChevronRight = props => (
  <svg width="8" height="12" viewBox="0 0 8 12" {...props}>
    <path d="M0.590088 10.59L5.17009 6L0.590088 1.41L2.00009 0L8.00009 6L2.00009 12L0.590088 10.59Z" />
  </svg>
);

export const ArrowCircleIcon = props => (
  <StyledIcon viewBox="0 0 20 20" {...props}>
    <path d="M10.0004 3.33328C13.6754 3.33328 16.6671 6.32495 16.6671 9.99995C16.6671 13.6749 13.6754 16.6666 10.0004 16.6666C6.32541 16.6666 3.33374 13.6749 3.33374 9.99995C3.33374 6.32495 6.32541 3.33328 10.0004 3.33328ZM10.0004 1.66661C5.40041 1.66661 1.66707 5.39995 1.66707 9.99995C1.66707 14.5999 5.40041 18.3333 10.0004 18.3333C14.6004 18.3333 18.3337 14.5999 18.3337 9.99995C18.3337 5.39995 14.6004 1.66661 10.0004 1.66661ZM10.8337 9.99995L10.8337 6.66661L9.16707 6.66661L9.16707 9.99995L6.66707 9.99995L10.0004 13.3333L13.3337 9.99995L10.8337 9.99995Z" />
  </StyledIcon>
);

export const OpenInNewTabIcon = props => (
  <StyledIcon viewBox="0 0 24 24" {...props}>
    <path d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z" />
  </StyledIcon>
);

export const ArrowDropdownCircle = props => (
  <StyledIcon viewBox="0 0 24 24" {...props}>
    <path d="M12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22ZM12 9L16 13L8 13L12 9Z" />
  </StyledIcon>
);

export const GrowthArrow = props => (
  <StyledIcon viewBox="0 0 18 16" {...props}>
    <path d="M6.42441 3.3335V4.66683H11.1286L2.85522 12.3935L3.86173 13.3335L12.1351 5.60683V10.0002H13.5628V3.3335H6.42441Z" />
  </StyledIcon>
);

export const BenchmarksIcon = props => (
  <StyledIcon viewBox="0 0 24 24" {...props}>
    <path d="M3.5 18.4898L9.5 12.4798L13.5 16.4798L22 6.91977L20.59 5.50977L13.5 13.4798L9.5 9.47976L2 16.9898L3.5 18.4898Z" />
  </StyledIcon>
);

export const CheckIcon = props => (
  <StyledIcon viewBox="0 0 24 24" {...props}>
    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
  </StyledIcon>
);

export const CalendarIcon = props => (
  <StyledIcon viewBox="0 0 24 24" {...props}>
    <path d="M9 11H7V13H9V11ZM13 11H11V13H13V11ZM17 11H15V13H17V11ZM19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z" />
  </StyledIcon>
);

export const DownloadIcon = props => (
  <StyledIcon viewBox="0 0 14 16" {...props}>
    <path d="M13.1765 5.64706H9.41177V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM5.64706 7.52941V1.88235H7.52941V7.52941H8.63059L6.58824 9.57176L4.54588 7.52941H5.64706ZM0 14.1176H13.1765V16H0V14.1176Z" />
  </StyledIcon>
);

