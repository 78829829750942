import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles'
import styled from '@emotion/styled'

const BarWrapper = styled('div')`
  height: 375px;
  width: 100%;
  max-width: 800px;

  canvas {
    height: 100% !important;
    width: 100% !important;
  }
`

const DistributionDuration = ({ data }) => {
  const durationLabels = data[0].datums.map((datum) => datum.x)
  const durationData = data[0].datums.map((datum) => datum.y)

  const maturityLabels = data[1].datums.map((datum) => datum.x)
  const maturityData = data[1].datums.map((datum) => datum.y)

  const labels = Array.from(new Set([...durationLabels, ...maturityLabels]))

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: data[0].label,
        data: labels.map((label) =>
          durationData[durationLabels.indexOf(label)] ?? null
        ),
        backgroundColor: '#037770'
      },
      {
        label: data[1].label,
        data: labels.map((label) =>
          maturityData[maturityLabels.indexOf(label)] ?? null
        ),
        backgroundColor: 'rgb(119, 147, 187)'
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom'
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.dataset.data[context.dataIndex]
            return value ? `${value.toFixed(2)}%` : ''
          }
        }
      },
      x: {
        scale: 0.5
      }
    }
  }

  return (
    <SectionWrapper>
      <SectionTitle>Distribution duration</SectionTitle>
      <BarWrapper>
        <Bar data={chartData} options={options} />
      </BarWrapper>
    </SectionWrapper>
  )
}

DistributionDuration.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      datums: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.string,
          y: PropTypes.number
        })
      )
    })
  ).isRequired
}

export default DistributionDuration
