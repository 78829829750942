interface VideoGridProps {
	videos: any
}

const VideoGrid = (props: VideoGridProps) => {

	const { videos } = props

	if (!videos) {
		return null
	}

	const videoList = videos.map((media: any) => {
		/** embeddedAsset */
		if (media.mimeType === 'application/json') {
			// Only render videos from embeddedAsset field
			if ( media.embeddedAsset && media.embeddedAsset.type === 'video' ) {
				return (
					<div key={media.id} className="embed-container" dangerouslySetInnerHTML={{ __html: media.embeddedAsset.html }} />
				)
			}
		}
	});

	return (
		<div className="video-grid">
			<div className="video-grid__wrapper">
				<div className="video-grid__content">
					{videoList}
				</div>
			</div>
		</div>
	)

}
export default VideoGrid
