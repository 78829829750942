import Link from 'next/link'
import LinkButton from '../../common/LinkButton'

interface SingleColumnTextProps {
	block: any
}

const SingleColumnText = (props: SingleColumnTextProps) => {

	const { block } = props

	if ( !block.body ) {
		return null
	}

	return (
		<div key={block.id} className="single-column-text">
			<div className="single-column-text__wrapper">
				<div className="single-column-text__content">
					<div dangerouslySetInnerHTML={{ __html: block.body }} />
					<LinkButton link={block.linkTo} />
				</div>
			</div>
		</div>
	)

}
export default SingleColumnText
