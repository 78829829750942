import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { TimeScale, TimeSeriesScale, LinearScale } from 'chart.js';
import PropTypes from 'prop-types';
import Box from '../../shared/components/Box';
import {
  Legend,
  LegendItem,
  LegendLabel
} from './styled/Legend';
import 'chartjs-adapter-date-fns';

// Register the date adapter with Chart.js
Chart.register(TimeScale, TimeSeriesScale, LinearScale);

const CustomChart = ({
  colors,
  data,
  hideLegend,
  showCrosshair,
  height,
  ...other
}) => {
  let chartData = {
    labels: data[0].datums.map((datum) => datum.x),
    datasets: data.map((item, index) => {
      return {
        label: item.label,
        data: item.datums.map((datum) => ({ x: datum.x, y: datum.y })),
        fill: false,
        borderColor: colors[index],
        backgroundColor: colors[index],
        pointRadius: 2
      };
    }),
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month'
        }
      },
      y: {
        ticks: {
          beginAtZero: true
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true
      },
      legend: {
        display: false
      },
      interaction: {
        mode: showCrosshair ? 'nearest' : 'index',
        intersect: false
      }
    }
  }

  return (
    <Fragment>
      <Box height={height}>
        <Line data={chartData} options={options} {...other} />
      </Box>
      {!hideLegend && (
        <Legend>
          {data.map((item, index) => (
            <LegendItem key={item.label}>
              <LegendLabel color={colors[index]} />
              {item.label}
            </LegendItem>
          ))}
        </Legend>
      )}
    </Fragment>
  )
}

CustomChart.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      datums: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.number,
            PropTypes.string

          ]),
          y: PropTypes.number
        })
      ),
      label: PropTypes.string
    })
  ).isRequired,
  hideLegend: PropTypes.bool,
  showCrosshair: PropTypes.bool,
  height: PropTypes.number
}

CustomChart.defaultProps = {
  hideLegend: false,
  showCrosshair: false,
  height: 240
}

export default CustomChart;
