import React, { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { Bar } from 'react-chartjs-2'
import { OutlineButton } from '../../shared/components/styled/Button'
import { ChevronLeftIcon } from '../../shared/icons'
import { SectionTitle } from '../styled/GlobalStyles'

const AllocationWrap = styled('div')`
  position: relative;
  margin-top: 20px;

  button {
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 1;
  }

  .tickLabel {
    direction: rtl;
  }
`

const SectorAllocation = ({ data, drilledData }) => {
  const [insideData, setDrilledData] = useState(null)

  if (!data || !data[0].datums.length) return null

  const rawData = insideData || data

  const formattedData = {
    labels: rawData[0].datums.map((datum) => datum.x),
    datasets: [
      {
        label: '',
        data: rawData[0].datums.map((datum) => datum.y),
        backgroundColor: '#037770'
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.dataset.data[context.dataIndex]
            return value ? `${value.toFixed(2)}%` : ''
          }
        }
      }
    }
  }

  const onBarClick = (event, chartElements) => {
    //TODO: Implement this
  }

  const onBack = () => {
    setDrilledData(null)
  }

  return (
    <Fragment>
      <SectionTitle>Sector allocation</SectionTitle>
      <AllocationWrap>
        {insideData && (
          <OutlineButton onClick={onBack}>
            <ChevronLeftIcon /> Back
          </OutlineButton>
        )}
        <Bar height={340} data={formattedData} onClick={onBarClick} options={options} />
      </AllocationWrap>
      {insideData && <SectorAllocation data={insideData} />}
    </Fragment>
  )
}

export default SectorAllocation
