import Link from 'next/link'

import LockUpWrapper from './partials/LockUpWrapper'

interface LockUpOpenPositionsProps {
	block: any
}


const LockUpOpenPositions = (props: LockUpOpenPositionsProps) => {

	const { block } = props

	if ( !block.selectedOpenPositions || block.selectedOpenPositions.length === 0 ) {
		return null
	}

	return (
		<LockUpWrapper block={block}>
			<>
				{block.selectedOpenPositions.map((position: any, index: number) => {
					return (
						<div key={`${block.id}-${index}`} className="lock-up__content-block">
							<h3>{position.previewTitle ? position.previewTitle : position.title}</h3>
							{position.previewText && <span dangerouslySetInnerHTML={{ __html: position.previewText }} />}
							<div className="lock-up__content-link">
								<Link href={`/${position.uri}`}>Read more and apply</Link>
							</div>
						</div>
					)
				})}
			</>
		</LockUpWrapper>
	)

}
export default LockUpOpenPositions
