import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { FundColor } from '../../shared/styles';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

const SpinnerWrapperStyles = css`
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
`

export const SpinnerWrapper = styled.div`
	${SpinnerWrapperStyles}
`


const Spinner = () => {
	return (
		<SpinnerWrapper>
			<TailSpin
				type="TailSpin"
				color={FundColor}
				width={40}
				height={40}
			/>
		</SpinnerWrapper>
	)
}

export default Spinner;
