export const TitleTextColor = '#136F9A';
export const MainTextColor = '#333333';
export const SubTextColor = '#7F7F7F';
export const PaleGrayColor = '#E0E0E0';
export const LightGrayColor = '#F3F3F3';
export const GrayColor = '#8B8B8B';
export const FundColor = '#8B8B8F';
// export const FundColor = 'var(--fund-color)';

export const AccentColor = '#27B1AB';
export const GreenColor = '#1BB67E';
export const RedColor = '#F90000';

export const BaseFont = 'font-size: 16px; line-height: 20px;';

export const chartColors = [
  FundColor,
  '#09CAC7',
  '#AD56CD',
  '#FF6969',
];

export const barColors = [
  FundColor,
  '#7793BB',
];

export const donutColors = [
  FundColor,
  MainTextColor,
  '#D1DBE7',
  '#7793BB',
  '#395175',
  '#ED7132',
];
