import axios from 'axios';

export const fetchNavPositions= async ({key, value, date, sort, limit}) => {
    const res = await axios.get( '/api/funds/nav-positions', {
        params: {
            key: key,
            value: value,
            sort: sort,
            limit: limit,
            ...(date && { date: date })
        }
      });


    return res;
}

export const fetchReturnChart = async ({key, value, date, sort, limit}) => {
    const res = await axios.get(`/api/funds/nav-positions/return-chart`, {
        params: {
          key: key,
          value: value,
          sort: sort,
          limit: limit,
          ...(date && { date: date })
        }
      })

      return res;
}