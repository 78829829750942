
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// import normalize from 'normalize.css';

import {
  MainTextColor,
  FundColor,
  BaseFont,
} from '../../shared/styles';


export const globalStyles = css`

  a {
    color: ${FundColor};
    outline-offset: -1px;

    &:hover {
      color: ${FundColor};
      text-decoration: none;
    }

    &:focus {
      outline: ${FundColor} dashed 1px;
    }
  }

  [data-whatinput='mouse'] *:focus,
  [data-whatinput='touch'] *:focus {
    outline: none !important;
    box-shadow: none;
  }

  button {
    cursor: pointer;
  }
`;

export default globalStyles;

export const SectionTitle = styled.h4`
  font-size: 32px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.1;
  text-transform: none !important;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 60px;
  min-height: 240px;
`;
