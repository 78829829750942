import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import Spinner from '../shared/components/Spinner';
import { Container, Row, Col } from '../shared/components/styled/Grid';
import AttributionYear from './components/AttributionYear';
import Return from './components/Return';
import DistributionDuration from './components/DistributionDuration';
import CreditQuality from './components/CreditQuality';
import SectorAllocation from './components/SectorAllocation';
import RiskWeight from './components/RiskWeight';
import DistributionCountries from './components/DistributionCountries';
import Facts from './components/Facts';
import Comments from './components/Comments';
import OtherMetrics from './components/OtherMetrics';
import TopHoldings from './components/TopHoldings';
import MonthlyReturn from './components/MonthlyReturn';
import KeyValues from './components/KeyValues';
import ContributionYear from './components/ContributionYear';
import ClassTabs from './components/ClassTabs';
import Panels from './components/Panels';

import KeyFigures from './components/KeyFigures/KeyFigures';
import KeyFiguresChart from './components/KeyFigures/Chart';

const CREDIT_CODES = ['AHR', 'AIG', 'AC'];
const EQUITY_CODES = ['SNW', 'SNE', 'ANE', 'AAL'];

const useClasses = portfolioCode => useQuery(['classes'], async () => {
	const { data } = await axios.get(`/api/funds/classes`, {
		params: {
			portfolioCode: portfolioCode,
			sort: 'class',
			limit: -1
		}
	});
	return data;
});

const useBenchmarks = () => useQuery(['benchmarks'], async () => {
	const { data } = await axios.get(`/api/funds/benchmarks`);

	return data
		.map(benchmark => ({ label: benchmark.name, value: benchmark.class }));
});

const useCredit = portfolioCode =>
	useQuery(['positions'], async () => {
		const { data } = await axios.get(`/api/funds/positions/charts`, {
			params: {
				portfolioCode: portfolioCode
			}
		});

		return data;
	});

export const ClassContext = React.createContext();

const FundPerformanceContent = ({ fund, mode, linkToPage, linkLabel }) => {
	const { status, data, error } = useClasses(fund.Portfolio_Code);
	const { data: positions } = useCredit(fund.Portfolio_Code);
	const { data: benchmarks } = useBenchmarks();
	const [activeClass, setActiveClass] = useState(null);

	useEffect(() => {
		if (data && !activeClass) {
			setActiveClass(data[0]);
		}
	}, [data, activeClass]);

	const onTabChange = (name) => {
		setActiveClass(data.find(c => c.class === name));
	};

	if (!activeClass || status === 'loading') return <Spinner />;
	if (status === 'error') {
		return (
			<span>Error: {error.message}</span>
		);
	}

	if (mode == 'keyFigures') {
		let ISIN = data[0] ? data[0].ISIN : null;

		return (
			<main>
				{/*<ClassContext.Provider
					value={{
						...activeClass,
						portfolioCode: fund.Portfolio_Code,
						benchmarks,
						locale: fund.locale,
					}}
				>*/}
					<KeyFigures ISIN={ISIN} locale={fund.locale} linkToPage={linkToPage} linkLabel={linkLabel} />
				{/*</ClassContext.Provider>*/}
			</main>
		)
	} else if (mode == 'chart') {
		return (
			<ClassContext.Provider
				value={{
					...activeClass,
					portfolioCode: fund.Portfolio_Code,
					benchmarks,
					locale: fund.locale,
				}}
			>
				<KeyFiguresChart />
			</ClassContext.Provider>
		)
	} else {
		return (
			<main>
				<ClassContext.Provider
					value={{
						...activeClass,
						portfolioCode: fund.Portfolio_Code,
						benchmarks,
						locale: fund.locale,
					}}
				>
					<Container>
						<Row>
							<Col>
								<ClassTabs setActiveTab={onTabChange} />
							</Col>
						</Row>
						<Row>
							<Col>
								<Panels />
							</Col>
						</Row>
						<Row>
							<Col>
								<Facts />
							</Col>
						</Row>
					</Container>
					<Comments />
					<Container>
						<Row>
							<Col>
								<Return />
							</Col>
						</Row>
						{CREDIT_CODES.includes(fund.Portfolio_Code) && positions && (
							<Fragment>
								<Row>
									<Col half>
										<CreditQuality data={positions.credit} />
									</Col>
									<Col half>
										<DistributionDuration data={positions.duration} />
									</Col>
								</Row>
								<Row>
									<Col half>
										<RiskWeight data={positions.risk} />
									</Col>
									<Col half>
										<OtherMetrics />
									</Col>
								</Row>
							</Fragment>
						)}
						{/*{EQUITY_CODES.includes(fund.Portfolio_Code) && (*/}
						{/*	<Row>*/}
						{/*		<Col>*/}
						{/*			<DistributionCountries data={positions?.mapData} />*/}
						{/*		</Col>*/}
						{/*	</Row>*/}
						{/*)}*/}
						<Row>
							<Col half>
								<TopHoldings data={positions?.top} />
							</Col>
							<Col half>
								<SectorAllocation
									data={positions?.allocation}
									drilledData={positions?.drilledAllocation}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<MonthlyReturn />
							</Col>
						</Row>
						<Row>
							<Col>
								<KeyValues />
							</Col>
						</Row>
						{EQUITY_CODES.includes(fund.Portfolio_Code) && (
							<Row>
								<Col half>
									<AttributionYear />
								</Col>
								<Col half>
									<ContributionYear />
								</Col>
							</Row>
						)}
					</Container>
				</ClassContext.Provider>
			</main>
		);
	}
};

FundPerformanceContent.propTypes = {
	fund: PropTypes.shape().isRequired,
};

export default FundPerformanceContent;
