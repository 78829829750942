import React from 'react'
import styled from '@emotion/styled'
import { GrowthArrow } from '../icons'
import { GreenColor, RedColor } from '../styles'

const StyledGrowth = styled('span')`
  display: flex;
  align-items: center;
  color: ${({ up }) => (up ? GreenColor : RedColor)};

  svg {
    margin-left: 5px;
    width: 15px;
    height: 15px;
    fill: ${({ up }) => (up ? GreenColor : RedColor)};
    transform: rotate(${({ up }) => (up ? 0 : '90deg')});
  }
`

const StyledDiv = styled('div')`
  color: #7A7A7A;
`

const Growth = ({ percent }) => {

  if (percent === null) {
    return (<StyledDiv>Insufficient data</StyledDiv>)
  }
  return <StyledGrowth up={Number(percent) > 0}>
    {percent}% <GrowthArrow />
  </StyledGrowth>

}

export default Growth
