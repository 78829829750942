import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { GrowthArrow } from '../../../shared/icons';

const GreenColor = '#00E6FF';
const RedColor = '#FFFFFF';

const StyledGrowth = styled('span')`
  display: flex;
  align-items: center;
  color: ${({ up }) => (up ? GreenColor : RedColor)};
  
  svg {
    margin-left: 5px;
    width: 15px;
    height: 15px;
    fill: ${({ up }) => (up ? GreenColor : RedColor)};
    transform: rotate(${({ up }) => (up ? 0 : '90deg')});
  }
`;

const Growth = ({ percent }) => {
  const percentRounded = parseFloat(percent).toFixed(1);

  return (
    <StyledGrowth up={Number(percent) > 0}>
      {percentRounded?.replace('.', ',')}% <GrowthArrow />
    </StyledGrowth>
  )
};

Growth.propTypes = {
  percent: PropTypes.string.isRequired,
};

export default Growth;
