import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { StyledTable } from './styled/Table'
import VerticalTable from '../../shared/components/VerticalTable'
import { scrollbarStyles } from './styled/ScrollBar'
import { LightGrayColor } from '../styles'

const TableWrapper = styled('div')`
  position: relative;
  ${scrollbarStyles};

  ${({ isScrollable }) => isScrollable && `
    th:first-of-type,
    td:first-of-type { 
      position: sticky;
      left: 0;
    }
    
    th:first-of-type {
      background-color: ${LightGrayColor};
    }
    
    td:first-of-type {
      background-color: #fff;
    }
    
    table {
      width: 900px;
      
      @media (min-width: 1440px) {
        width: 100%;
      }
    }
  `}
`

const Table = ({
  columns, rows, isVertical, isScrollable, isFixedFirstColumn
}) => {
  const renderRow = (row, i) => (
    <tr key={row.id + '-' + i}>
      {columns.map(({ index, render, key }) => (
        render ?
          <td key={`${row.id}-${key}`}>{render(row[index])}</td> :
          <td key={`${row.id}-${key}`}>{row[index] || '-'}</td>
      ))}
    </tr>
  )

  return (
    <TableWrapper
      isFixedFirstColumn={isFixedFirstColumn}
      isScrollable={isScrollable}
    >
      <StyledTable hideOnMobile={isVertical}>
        <thead>
        <tr>
          {columns.map(item => <th key={item.key}>{item.title}</th>)}
        </tr>
        </thead>
        <tbody>
        {rows.map((row, index) => renderRow(row, index))}
        </tbody>
      </StyledTable>
      {isVertical && <VerticalTable columns={columns} rows={rows} />}
    </TableWrapper>
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    render: PropTypes.func
  })).isRequired,
  isScrollable: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isVertical: PropTypes.bool,
  isFixedFirstColumn: PropTypes.bool
}

Table.defaultProps = {
  isVertical: false,
  isScrollable: false,
  isFixedFirstColumn: false
}

export default Table
