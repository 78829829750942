import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import Table from '../../shared/components/Table';
import { ClassContext } from '../FundPerformanceContent';
import Select from '../../shared/components/Select';
import { fetchNavPositions, fetchReturnChart } from '../../utils/services/api'

const TableWrapper = styled('div')`
  margin-top: 72px;

  td > div {
    margin: 0 0 0 -8px;
  }

  & > div {
    overflow: visible;
  }
`;

const addPercentSymbol = value =>
  (value ? `${Number(value).toFixed(2)}%` : '-');

const columns = [
  {
    title: 'Return',
    key: 'name',
    index: 'name',
  }, {
    title: 'Month to date',
    key: 'monthToDate',
    index: 'monthToDate',
    render: addPercentSymbol,
  }, {
    title: 'Last 3 months',
    key: 'pa3M',
    index: 'pa3M',
    render: addPercentSymbol,
  }, {
    title: 'Last 6 months',
    key: 'pa6M',
    index: 'pa6M',
    render: addPercentSymbol,
  }, {
    title: 'Year to date',
    key: 'yearToDate',
    index: 'yearToDate',
    render: addPercentSymbol,
  }, {
    title: 'Last 12 months',
    key: 'pa12M',
    index: 'pa12M',
    render: addPercentSymbol,
  }, {
    title: 'Last 36 months',
    key: 'pa36M',
    index: 'pa36M',
    render: addPercentSymbol,
  }, {
    title: 'Last 60 months',
    key: 'pa60M',
    index: 'pa60M',
    render: addPercentSymbol,
  }, {
    title: 'Since inception',
    key: 'inceptionDate2',
    index: 'inceptionDate2',
    render: addPercentSymbol,
  },
];

const getDataByDate = async (date, key, value) => {
  const { data } = await fetchNavPositions({key, value, date, sort: 'rDate:DESC', limit: 1});

	return data[0];
};

const fetchData = async (key, value, inceptionDate2) => {
  const { data } = await fetchNavPositions({key, value, sort: 'rDate:DESC', limit: 1});


  const formattedData = data[0];
  if (formattedData?.rDate !== undefined) {
    const formattedDate = new window.Date(formattedData.rDate);
    
    // yearToDate
    let date = new window.Date(formattedDate.getFullYear() - 1, 11, 31);

    let dateData = await getDataByDate(date, key, value);
    formattedData.yearToDate = dateData &&
      (((formattedData.NAV / dateData.NAV) - 1) * 100).toFixed(2);

    // monthToDate
    date = new window.Date(
      formattedDate.getFullYear(),
      formattedDate.getMonth(),
      0,
    );

    dateData = await getDataByDate(date, key, value);
    formattedData.monthToDate = dateData &&
      (((formattedData.NAV / dateData.NAV) - 1) * 100).toFixed(2);

    // pa6M
    date = new window.Date(
      formattedDate.getFullYear(),
      formattedDate.getMonth() - 6,
      formattedDate.getDate(),
    );

    dateData = await getDataByDate(date, key, value);
    formattedData.pa6M = dateData &&
      (((formattedData.NAV / dateData.NAV) - 1) * 100).toFixed(2);

    // pa3M
    date = new window.Date(
      formattedDate.getFullYear(),
      formattedDate.getMonth() - 3,
      formattedDate.getDate(),
    );

    dateData = await getDataByDate(date, key, value);
    formattedData.pa3M = dateData &&
      (((formattedData.NAV / dateData.NAV) - 1) * 100).toFixed(2);

    // pa36M
    date = new window.Date(
      formattedDate.getFullYear(),
      formattedDate.getMonth() - 36,
      formattedDate.getDate(),
    );
    dateData = await getDataByDate(date, key, value);
    formattedData.pa36M = dateData &&
      (((formattedData.NAV / dateData.NAV) - 1) * 100).toFixed(2);

    // pa60M
    date = new window.Date(
      formattedDate.getFullYear(),
      formattedDate.getMonth() - 60,
      formattedDate.getDate(),
    );

    dateData = await getDataByDate(date, key, value);
    formattedData.pa60M = dateData &&
      (((formattedData.NAV / dateData.NAV) - 1) * 100).toFixed(2);

    // inceptionDate
    const { data: iData } = await fetchNavPositions({key, value, sort: inceptionDate2 ? 'rDate:DESC' : 'rDate:ASC', date: inceptionDate2 ? new Date(inceptionDate2) : null,  limit: 1});

	  formattedData.inceptionDate2 = iData[0] &&
      (((formattedData.NAV / iData[0].NAV) - 1) * 100).toFixed(2);
  }

  return formattedData;
};

const useNavpositions = ISIN =>
  useQuery(
    ['returnCalculations', ISIN],
    () => fetchData('ISIN', ISIN),
  );

const useBenchmarkNavpositions = (name, inceptionDate2) =>
  useQuery(
    ['returnCalculationsBenchmark', name],
    async () => {
      if (!name) return { data: null };
      return fetchData('name', name, inceptionDate2);
    },
  );

const Return = () => {
  const { ISIN, benchmark, benchmarks, inceptionDate2 } = useContext(ClassContext);

  const [selectedBenchmark, setSelectedBenchmark] = useState(benchmark ?
    { label: benchmark.name, value: benchmark.class } : null);
  const { data: benchData } =
    useBenchmarkNavpositions(selectedBenchmark?.value, inceptionDate2);
  const { data } = useNavpositions(ISIN);

  useEffect(() => {
    setSelectedBenchmark(benchmark ?
      { label: benchmark.name, value: benchmark.class } : null);
  }, [ISIN, benchmark]);

  const handleSelect = value => setSelectedBenchmark(value);


  const renderSelect = (
    <Select
      options={benchmarks}
      isSearchable={false}
      hideSelectedOptions={false}
      value={selectedBenchmark}
      onChange={handleSelect}
      width="100%"
    />
  );

  if (!data) return null;

  let different = {};

  if (selectedBenchmark && benchData) {
    different = {
      monthToDate: data.monthToDate - benchData.monthToDate,
      pa3M: data.pa3M - benchData.pa3M,
      pa6M: data.pa6M - benchData.pa6M,
      pa12M: data.pa12M - benchData.pa12M,
      pa36M: data.pa36M - benchData.pa36M,
      pa60M: data.pa60M - benchData.pa60M,
      inceptionDate2: data.inceptionDate2 - benchData.inceptionDate2,
      yearToDate: data.yearToDate - benchData.yearToDate,
    };
  }

  return (
    <TableWrapper>
      <Table
        rows={[
          data,
          { ...(selectedBenchmark ? benchData : {}), name: renderSelect },
          { name: 'Difference', ...different },
        ]}
        columns={columns}
        isVertical
      />
    </TableWrapper>
  );
};

export default Return;