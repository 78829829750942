import React, { useEffect, useState, useRef } from 'react'
import { format } from 'date-fns'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { CalendarIcon } from '../../../shared/icons'

const SelectedDate = ((props) => {
  const date = format(new Date(props.selected), 'dd. MMMM yyyy')
  return (
    <div>{date}</div>
  )
});

const DateSelect = ((props) => {
  const { name, selected, onSelect, onOpen, closeCalendar, className } = props;
  const [isOpen, setIsOpen] = useState(false);
  const selectDateRef = useRef();

  useEffect(() => {
    setIsOpen(!closeCalendar);
  }, [closeCalendar]);

  useEffect(() => {
      document.addEventListener("mousedown", handleClick);

      return () => {
          document.removeEventListener("mousedown", handleClick);
      };
  }, []);

  const handleClick = (evt) => {
    if (!selectDateRef.current.contains(evt.target)) {
        setIsOpen(false);
    }
  };

  const toggleCalendar = () => {
    setIsOpen(!isOpen);
    onOpen(name);
  }

  return (
    <div ref={selectDateRef} className={`select-date-wrapper ${className}`}>
      <button className="date-selected-wrapper" onClick={toggleCalendar}>
        <CalendarIcon />
        <SelectedDate selected={selected} />
      </button>
      <DayPicker
        mode="single"
        selected={selected}
        onSelect={onSelect}
        className={isOpen ? 'calendar-open': ''}
      />
    </div>
  )
})

export default DateSelect;