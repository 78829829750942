import Link from 'next/link'

import ResponsiveImage from '../../common/ResponsiveImage'
import ManagementList from './partials/ManagementList'
import TeamList from './partials/TeamList'

interface PeopleProps {
	block: any
}

const People = (props: PeopleProps) => {

	const { block } = props

	if ( block.personBlockLayout === "management" ) {
		return <ManagementList block={block} />
	}

	if ( block.personBlockLayout === "team" || block.personBlockLayout === "contact" || block.personBlockLayout === "contactWithoutImage") {
		return <TeamList block={block} showContactInfo={block.personBlockLayout === "contact" || block.personBlockLayout === "contactWithoutImage"} hideImage={block.personBlockLayout === "contactWithoutImage"}/>
	}

	return null

}
export default People
