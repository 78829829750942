import Link from 'next/link'

import LockUpWrapper from './partials/LockUpWrapper'
import LockUpContentHeader from './partials/LockUpContentHeader'
import LinkButton from '../../common/LinkButton'

interface CustomContentListProps {
	block: any
}

const CustomContentList = (props: CustomContentListProps) => {

	const { block } = props

	return (
		<section className="custom-content-list">
			<div className="custom-content-list__wrapper">
				{block.heading &&
					<div className="custom-content-list__header">
						<h2>{block.heading}</h2>
					</div>
				}
				{block.simpleContentBlocks.map((content: any) => {
					return (
						<div key={content.id} className={`custom-content-list__item custom-content-list__item--size-${block.layout ? block.layout : 'three-column'}`}>
							{content.heading && <h3>{content.heading}</h3>}
							{content.text && <span dangerouslySetInnerHTML={{ __html: content.text }} />}
							<LinkButton
								link={content.linkTo}
								linkClass="button button--inverted"
							/>
						</div>
					)
				})}
			</div>
		</section>
	)

}
export default CustomContentList
