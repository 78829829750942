interface NewsletterProps {
	block: any
}


const Newsletter = (props: NewsletterProps) => {

	const { block } = props

	return (
		<div key={block.id} className="newsletter">
			<div className="newsletter__wrapper">
				<div className="newsletter__container">
					{block.heading && <h2>{block.heading}</h2>}
					{block.description && <p>{block.description}</p>}
					<div className="newsletter--form" dangerouslySetInnerHTML={{ __html: block.codeBlock }} />
				</div>
			</div>
		</div>
	)

}

export default Newsletter
