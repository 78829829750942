import React, { useContext } from 'react';
import { ArrowDropdownCircle, OpenInNewTabIcon } from '../../shared/icons';
import styled from '@emotion/styled';
import { FundColor } from '../../shared/styles';
import useCollapse from '../../utils/hooks/Collapse';
import { ClassContext } from '../FundPerformanceContent';

const Container = styled('div')`
  margin-bottom: 60px;
`;

const CollapsedList = styled('ul')`
  padding: 0;
  list-style: none;
  margin: 15px 0 0 35px;
  max-height: ${({ isCollapsed }) => (isCollapsed ? '0' : '700px')};
  overflow: hidden;
  transition: 0.3s;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    svg {
      margin-right: 5px;
    }

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CollapseButton = styled('button')`
  background-color: transparent;
  border: none;
  color: ${FundColor};
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    transform: rotate(${({ isCollapsed }) => (isCollapsed ? '0' : '180deg')});
    transition: 0.3s;
  }
`;

const Facts = () => {
  const { isCollapsed, toggleCollapse } = useCollapse(false);
  const { documents } = useContext(ClassContext);

  if (!documents?.length) return null;

  return (
    <Container>
      <CollapseButton isCollapsed={isCollapsed} onClick={toggleCollapse}>
        <ArrowDropdownCircle /> Facts
      </CollapseButton>
      <CollapsedList isCollapsed={isCollapsed}>
        {documents.map(document => (
          <li key={document.id}>
            <OpenInNewTabIcon />
            <a
              href={`api${document.url}`}
              target="_blank"
              rel="noreferrer"
            >
              {document.name}
            </a>
          </li>
        ))}
      </CollapsedList>
    </Container>
  );
};

export default Facts;
