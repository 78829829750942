import React, { useState, useContext, useEffect } from 'react'
import { useQueries, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Spinner from '../../shared/components/Spinner'
import Table from '../../shared/components/Table'
import Select from '../../shared/components/Select'
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles'
import { ClassContext } from '../FundPerformanceContent'

const toFixed = value => (value ? Number(value).toFixed(2) : '-')

const addPercentSymbol = value =>
  (value ? `${toFixed(value)}%` : '-')

const columns = [{
  title: '',
  key: 'name',
  index: 'name'
}, {
  title: 'Standard deviation',
  key: 'STD',
  index: 'STD',
  render: addPercentSymbol
}, {
  title: 'Sharpe Ratio',
  key: 'sharpeRatio',
  index: 'sharpeRatio',
  render: toFixed
}, {
  title: 'Tracking Error',
  key: 'trackingError',
  index: 'trackingError',
  render: addPercentSymbol
}, {
  title: 'Information ratio',
  key: 'infoRatio',
  index: 'infoRatio',
  render: toFixed
}]

const useKeyValues = isin => useQuery(['keyValues', isin], async () => {
  const { data } = await axios.get(`/api/funds/nav-positions/key-values`, {
    params: {
      key: 'ISIN',
      value: isin
    }
  })
  return data[0]
})

const fetchBenchmark = async (benchmark) => {
  const { data } = await axios.get(`/api/funds/nav-positions/key-values`, {
    params: {
      key: 'name',
      value: benchmark.value
    }
  })

  return data[0] || { name: benchmark.value }
}

const useBenchmarksKeyValues = benchmarks =>
  useQueries({
    queries: benchmarks.map(b => ({
      queryKey: ['benchmarksKeyValues', b],
      queryFn: () => fetchBenchmark(b)
    }))
  })

// TODO: Figure out what this should display - Dont work in prod.
const KeyValues = () => {
  const {
    ISIN,
    benchmarks,
    benchmark
  } = useContext(ClassContext)

  const { isLoading, isSuccess, isError, data, error } = useKeyValues(ISIN)

  const [selectedBenchmarks, setSelectedBenchmarks] = useState(benchmark ?
    [{ label: benchmark.name, value: benchmark.class }] : [])
  const benchmarksQueries = useBenchmarksKeyValues(selectedBenchmarks)

  useEffect(() => {
    setSelectedBenchmarks(benchmark ?
      [{ label: benchmark.name, value: benchmark.class }] : [])
  }, [ISIN, benchmark])

  const handleSelect = value => setSelectedBenchmarks(value)

  if (isError) {
    return (
      <span>Error: {error.message}</span>
    )
  }

  const rows = [data, ...benchmarksQueries.map(bm => bm.data)].filter(Boolean)

  return (
    <SectionWrapper>
      <SectionTitle>Key values</SectionTitle>
      <Select
        isSearchable={false}
        options={benchmarks}
        hideSelectedOptions={false}
        isMulti
        value={selectedBenchmarks}
        onChange={handleSelect}
      />
      {isLoading && <Spinner />}
      {isSuccess && <Table rows={rows} columns={columns} />}
    </SectionWrapper>
  )
}

export default KeyValues
