/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { SubTextColor } from '../../shared/styles';

const StyledBox = styled('div')`
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  
  .axes .domain {
    stroke: none !important;
  }
  
  .tickLabel {
    fill: ${SubTextColor} !important;
    font-size: 12px !important;
  }
  
  .Axis:nth-of-type(2) .ticks .tick:nth-of-type(2n) {
    display: none;
  }
  
  .gridLine,
  .tickline {
    stroke: #F3F3F3 !important;
  }

  .Cursor > div:first-of-type {
    background: none !important;
    ${props => props.showCrosshair &&
      'border-left: 1px dashed #333 !important;'}
  }

  .Cursor div div {
    padding: 0 5px !important;
  }
`;

const Box = ({
  children,
  height = 300,
  width,
  showCrosshair,
}) => (
  <StyledBox height={height} width={width} showCrosshair={showCrosshair}>
    {children}
  </StyledBox>
);

export default Box;
