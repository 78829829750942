import Link from 'next/link'

import ResponsiveImage from '../../../common/ResponsiveImage'

interface TeamListProps {
	block: any
	showContactInfo?: boolean
	hideImage?: boolean
}

const TeamList = (props: TeamListProps) => {

	const { block, showContactInfo, hideImage } = props

	if (!block.selectedPeople || block.selectedPeople.length === 0) {
		return null
	}

	return (
		<div className="team-list">
			<div className="team-list__wrapper">

				{block.heading &&
					<div className="team-list__heading">
						<h2>{block.heading}</h2>
					</div>
				}

				{block.selectedPeople.map((person: any, index: number) => {

					const image = person.image && person.image.length > 0 ? person.image[0] : null

					return (
						<div key={person.id} className={`team-list__item ${hideImage ? 'team-list__item--no-image' : 'team-list__item--with-image'}`}>
							{ !hideImage &&
								<div className="team-list__item-media">
									{image ? (
										<ResponsiveImage
											image={image}
											containerClass={`team-list__item-media-container`}
											cleanImplementation={true}
										/>
									) : (
										<div className="team-list__item-media-placeholder" />
									)}

								</div>
							}
							<div className={`team-list__item-content`}>
								<h4>{person.title}</h4>
								<p>{person.workTitle}</p>
								{showContactInfo && (person.email || person.phone) ? (
									<ul className="team-list__contact-list">
										{person.email &&
											<li className="team-list__contact-list-item team-list__contact-list-item--email">
												<a href={`mailto:${person.email.replaceAll(' ', '')}`}>{person.email}</a>
											</li>
										}
										{person.phone &&
											<li className="team-list__contact-list-item team-list__contact-list-item--phone">
												<a href={`tel:${person.phone.replace('+', '00').replaceAll(' ', '')}`}>{person.phone}</a>
											</li>
										}
									</ul>
								) : null}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)

}
export default TeamList
