import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';
import Box from '../../shared/components/Box';
import { donutColors } from '../../shared/styles';
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles';

const RiskWrap = styled('div')`
  display: flex;
`;

const PieLegend = styled('div')`
  margin-left: 60px;
`;

const PieLegendItem = styled('div')`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const PieLegendColor = styled('div')`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${props => props.color};
`;

const RiskWeight = ({ data }) => {
  if (!data) return null;

  return (
    <SectionWrapper>
      <SectionTitle>Risk weight</SectionTitle>
      <RiskWrap>
        <Box height={200} width={200}>
          <PieChart
            lineWidth={50}
            data={data
              .map((item, index) => ({
                ...item,
                color: donutColors[index],
              }))}
          />
        </Box>
        <PieLegend>
          {data.map((item, index) => (
            <PieLegendItem key={item.title}>
              <PieLegendColor color={donutColors[index]} /> {item.title}
            </PieLegendItem>
          ))}
        </PieLegend>
      </RiskWrap>
    </SectionWrapper>
  );
};

RiskWeight.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
};

export default RiskWeight;
