/**
 * Get rounded value in decimal places
 * Note: we're not using only toFixed() here because of precission issues.
 * e.g. 1.35.toFixed(1) and 1.45.toFixed(1) both returns 1.4
 */
export const roundToDecimals = (val, decimals = 0) => {
  // If value is invalid return 0
  if (!parseFloat(val)) {
    return 0;
  }

  let negative = false;

  if (val < 0) {
      negative = true;
      val = val * -1;
  }

  const multiplicator = Math.pow(10, decimals);

  val = parseFloat((val * multiplicator).toFixed(11));
  val = (Math.round(val) / multiplicator).toFixed(decimals);

  return negative ? (val * -1).toFixed(decimals) : val;
}