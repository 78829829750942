import React, { useContext, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { FundColor, LightGrayColor } from '../../shared/styles';
import useCollapse from '../../utils/hooks/Collapse';
import { scrollbarStyles } from '../../shared/components/styled/ScrollBar';
import { SectionWrapper } from '../styled/GlobalStyles';
import { ClassContext } from '../FundPerformanceContent';

const CommentsWrapper = styled('div')`
  background-color: ${LightGrayColor};
  border-radius: 4px;
  padding: 30px 40px;
  max-width: 1030px;
  display: flex;
  flex-direction: column;

  h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 1.1;
    font-weight: 500;
  }

  button {
    padding: 0;
    border: none;
    color: ${FundColor};
    font-weight: bold;
    border-bottom: 1px solid #C8C8C8;
    background-color: transparent;
    align-self: flex-end;
    margin-top: 10px;
  }

  @media (min-width: 768px) {
    max-width: 990px;
  }

  @media (min-width: 1200px) {
    margin-left: 40px;
    max-width: 900px;
    padding: 30px 35px;
  }
`;

// TODO: add counting height to js

const CollapseWrapper = styled('div')`
  ${scrollbarStyles};

  @keyframes collapse-in {

    from {
      overflow: auto;
      -webkit-line-clamp: initial;
      max-height: 500px;
    }

    to {
      overflow: hidden;
      max-height: 40px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  @keyframes collapse-out {

    from {
      overflow: hidden;
      max-height: 40px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    to {
      overflow: auto;
      -webkit-line-clamp: initial;
      max-height: 500px;
    }
  }

  p {
    ${scrollbarStyles};
    display: -webkit-box;

    ${({ isCollapsed }) => (isCollapsed ? `
      animation: collapse-in 0.3s linear;
      overflow: hidden;
      max-height: 40px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    ` : `
      animation: collapse-out 0.3s linear;
      overflow: auto;
      -webkit-line-clamp: initial;
      max-height: 500px;
    `)}
    white-space: pre-line;
  }
`;

const Comments = () => {
  const wrapperRef = useRef(null);
  const { isCollapsed, toggleCollapse } = useCollapse();
  const { fund } = useContext(ClassContext);

  useEffect(() => {
    if (wrapperRef?.current) {
      wrapperRef.current.scrollTo(0, 0);
    }
  }, [isCollapsed]);
  if (!fund || !fund.commentTitle || !fund.comment) return null;

  return (
    <SectionWrapper>
      <CommentsWrapper>
        <h3>{fund.commentTitle}</h3>
        <CollapseWrapper isCollapsed={isCollapsed}>
          <p ref={wrapperRef}>{fund.comment}</p>
        </CollapseWrapper>
        <button onClick={toggleCollapse}>
          {isCollapsed ? 'See more' : 'Collapse' }
        </button>
      </CommentsWrapper>
    </SectionWrapper>
  );
};

export default Comments;
