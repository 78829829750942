import React, { useContext, useEffect, useMemo, useState } from 'react'
import { curveLinear } from 'd3-shape'
import axios from 'axios'

import Chart from '../../../shared/components/Chart'
import { chartColors } from '../../../shared/styles'
import Select from '../../../shared/components/Select'
import CustomTooltip from '../../../shared/components/ChartTooltip'
import styled from '@emotion/styled'
import { SectionTitle, SectionWrapper } from '../../styled/GlobalStyles'
import ReturnTable from '../ReturnTable'
import ReturnTableMonths from '../ReturnTableMonths'
import { ClassContext } from '../../FundPerformanceContent'
import DateSelect from './DateSelect'

const SelectsWrapper = styled('div')`
  display: flex;
  gap: 4rem;
  margin-bottom: 2rem;
`

const ChartWrapper = styled('div')`
  margin-bottom: 60px;
`

const fetchReturn = async (isin, gteDate, lteDate, name) => {
  const { data } = await axios.get(`/api/funds/nav-positions/return-chart`, {
    params: {
      key: 'ISIN',
      value: isin,
      sort: 'rDate:ASC',
      date: gteDate,
      lteDate: lteDate,
      limit: -1
    }
  })
  return [{ ...data[0], label: name }]
}

const fetchBenchmark = async (benchmark, gteDate, lteDate) => {
  const { data } = await axios.get(`/api/funds/nav-positions/return-chart`, {
    params: {
      key: 'name',
      value: benchmark.value,
      sort: 'rDate:ASC',
      limit: -1,
      date: gteDate,
      lteDate: lteDate
    }
  })

  return { ...data[0], label: benchmark.label }
}

const KeyFiguresChart = () => {
  const {
    ISIN,
    inceptionDate2: inceptionDate,
    benchmarks,
    name,
    benchmark
  } = useContext(ClassContext)

  const now = new window.Date()
  now.setHours(0, 0)


  const [selectedBenchmarks, setSelectedBenchmarks] = useState([])
  const [data, setData] = useState([])
  const [chartData, setChartData] = useState([])
  const [benchmarkData, setBenchmarkData] = useState([])

  const [startDateSelected, setStartDateSelected] = useState(new Date(now.getFullYear(), now.getMonth(), 1));
  const [endDateSelected, setEndDateSelected] = useState(new Date());

  const [openMenuName, setOpenMenuName] = useState(null);
  
  useEffect(() => {
    const getReturnData = async () => {
      // return await fetchReturn(ISIN, selectedDate.value, name)
      return await fetchReturn(ISIN, startDateSelected, endDateSelected, name)
    }
    getReturnData().then(data => {
      const dataObj = data;
      setData(data)
      setChartData(data)
    })

    if (benchmarks) {
      const getBenchmarkData = async () => {
        // const promises = await benchmarks.map(async bm => await fetchBenchmark(bm, selectedDate.value))
        const promises = await benchmarks.map(async bm => await fetchBenchmark(bm, startDateSelected, endDateSelected))

        return Promise.all(promises)
      }
      getBenchmarkData().then(b => setBenchmarkData(b))
    }
    if (benchmark) {
      setSelectedBenchmarks([{ label: benchmark.name, value: benchmark.class }])
    }
  }, [ISIN, benchmark, benchmarks, name, startDateSelected, endDateSelected])

  useEffect(() => {
    const updateChartDataWithSelectedBenchmarks = () => {
      const filter = benchmarkData.filter(bms =>
        selectedBenchmarks.some(sBms => sBms.label === bms.label)
      );
      setChartData([...data, ...filter]);
    };

    updateChartDataWithSelectedBenchmarks();
  }, [selectedBenchmarks, benchmarkData, data]);

  const series = useMemo(
    () => ({
      showPoints: false,
      curve: curveLinear
    }),
    []
  )

  const axes = useMemo(
    () => [
      {
        primary: true,
        type: 'utc',
        position: 'bottom',
        showGrid: false
      },
      {
        type: 'linear',
        position: 'left',
        format: () => ''
      },
      {
        type: 'linear',
        position: 'right',
        format: value => `${value}%`
      }
    ],
    []
  )

  const tooltip = useMemo(
    () => ({
      render: ({ datum, secondaryAxis }) => (
        <CustomTooltip
          {...{
            secondaryAxis: {
              ...secondaryAxis,
              format: d => `${d.toFixed(2)}%`
            },
            datum,
            isDate: true
          }}
        />
      )
    }),
    []
  )

  if (data.length === 0) return null

  console.log('chartData', chartData)


  return (
    <SectionWrapper>
      <SectionTitle>Return</SectionTitle>
      <SelectsWrapper>
        <DateSelect 
          name={'startDate'} 
          selected={startDateSelected} 
          onSelect={setStartDateSelected} 
          onOpen={(name) => setOpenMenuName(name)}
          closeCalendar={openMenuName === 'startDate' ? false : true}
          className='start-date'
        />
        <DateSelect 
          name={'endDate'} 
          selected={endDateSelected} 
          onSelect={setEndDateSelected} 
          onOpen={(name) => setOpenMenuName(name)}
          closeCalendar={openMenuName === 'endDate' ? false : true}
          className='end-date'
        />
      </SelectsWrapper>
      <ChartWrapper>
        {chartData &&
          <Chart
            colors={chartColors}
            data={chartData}
            axes={axes}
            series={series}
            showCrosshair
            tooltip={tooltip}
          />
        }
      </ChartWrapper>
    </SectionWrapper>
  )
}

export default KeyFiguresChart
