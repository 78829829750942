import Link from 'next/link'

import LockUpWrapper from './partials/LockUpWrapper'
import LockUpContentHeader from './partials/LockUpContentHeader'
import LinkByType from '../../common/LinkByType'

interface LockUpBasicProps {
	block: any
}

const LockUpBasic = (props: LockUpBasicProps) => {

	const { block } = props

	return (
		<LockUpWrapper block={block}>
			<>
				<LockUpContentHeader block={block} />
				<div key={block.id} className="lock-up__content-block">
					<ul className='link-list'>
						{block.links.map((link: any) => {

							const linkTo = link.linkTo
							if ( linkTo && linkTo.url ) {
								return (
									<li key={link.id} className='link-list__item'>
										<LinkByType link={linkTo} linkClass={`link-list__link`} addArrowRight={true} />
									</li>
								)
							}

						})}
					</ul>
				</div>
			</>
		</LockUpWrapper>
	)

}
export default LockUpBasic
