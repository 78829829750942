import React, { useEffect, useState, memo } from 'react'
import { dsv } from 'd3-fetch'
import { scaleLinear } from 'd3-scale'
import styled from '@emotion/styled'
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from 'react-simple-maps'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { FundColor } from '../../shared/styles'
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles'

const TooltipContent = styled('div')`
  span {
    display: block;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }
`

const geoUrl =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json'

const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(['#c8f1ef', FundColor])

// eslint-disable-next-line react/prop-types
// eslint-disable-next-line react/display-name
const MapChart = memo(({ setContent, data }) => {
  const [countries, setCountries] = useState([])
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    dsv(';', '/countries.csv').then((extractedData) => {
      setCountries(extractedData)
    })
  }, [])

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line react/prop-types
      setFormattedData(data.map(value => ({
        ...value,
        ISO3: countries.find(c => c.English === value.country
          || c.Norwegian === value.country)?.ISO3
      })))
    }
  }, [data, countries])

  return (
    <ComposableMap height={500}>
      <ZoomableGroup zoom={1}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const d = formattedData.find(s => s.ISO3 === geo.id)
              const name = geo.properties.name

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d.value) : '#F5F4F6'}
                  data-tooltip-id="country-tooltip"
                  data-tooltip-content={name}
                  onMouseEnter={() => {
                    setContent({ name: name, value: d?.value ?? '' })
                  }}
                  onMouseLeave={() => {
                    setContent(null)
                  }}
                  style={{
                    default: {
                      fill: '#D6D6DA',
                      outline: 'none'
                    },
                    hover: {
                      fill: '#F53',
                      outline: 'none'
                    },
                    pressed: {
                      fill: '#E42',
                      outline: 'none'
                    }
                  }}
                />
              )
            })
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>

  )
})

// TODO: Figure out what this actually should do. Doesn't work in prod
// eslint-disable-next-line react/prop-types
const DistributionCountries = ({ data }) => {
  const [content, setContent] = useState(null)

  return (
    <SectionWrapper>
      <SectionTitle>Portfolio distribution by countries</SectionTitle>
      <MapChart setContent={setContent} data={data} />
      <ReactTooltip>
        {content && (
          <TooltipContent>
            <span>{content.name}</span>
            {/*<span>{content.value.toFixed(2)}%</span>*/}
          </TooltipContent>
        )}
      </ReactTooltip>
    </SectionWrapper>
  )
}

export default DistributionCountries
