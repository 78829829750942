import { getSrcSet } from '../../../lib/helpers/images'

interface MorningstarProps {
	block: any
}

const Morningstar = (props: MorningstarProps) => {

	const { block } = props
	const image = block.image && block.image.length > 0 ? block.image[0] : null;

	return (
		<div className={`morningstar`}>
			<div className="morningstar__wrapper">
				<div className="morningstar__media">
					{ image && <img
						src={image.url}
						width={image.width}
						height={image.height}
						srcSet={getSrcSet(image)}
						sizes={'100vw'}
						alt={image.title}
					/>}
				</div>
				<div className="morningstar__content">
					<div className="morningstar__content-block">
						{block.heading && <h2>{block.heading}</h2>}
						{block.body && <span dangerouslySetInnerHTML={{ __html: block.body }} />}
					</div>
				</div>
			</div>
		</div>
	)

}
export default Morningstar
