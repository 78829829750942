import styled from '@emotion/styled';

export const Legend = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 32px;
  
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LegendItem = styled('p')`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: ${props => (props.tooltip ? '4px' : '16px')} !important;
`;

export const LegendLabel = styled('span')`
  background: ${props => props.color};
  display: block;
  width: 20px;
  height: 3px;
  border-radius: 2px;
  margin-right: 8px;
`;
