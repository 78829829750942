import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../shared/components/Table';
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles';

const columns = [{
  title: 'Company',
  key: 'security',
  index: 'security',
}, {
  title: 'Weight',
  key: 'portfolioWeight',
  index: 'portfolioWeight',
  render: (string) => {
    if (string) {
      return string[string.length - 1] === '%' ? string : `${string} %`;
    }
    return '-';
  },
}];

const TopHoldings = ({ data }) => {
  if (!data || !data.length) return null;

  return (
    <SectionWrapper>
      <SectionTitle>Top 10 holdings</SectionTitle>
      <Table rows={data} columns={columns} />
    </SectionWrapper>
  );
};

TopHoldings.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    security: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    riskWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
};

TopHoldings.defaultProps = {
  data: null,
};

export default TopHoldings;
