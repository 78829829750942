import Link from 'next/link'
import LinkButton from '../../common/LinkButton'

import ResponsiveImage from '../../common/ResponsiveImage'
import { 
	YoutubeIcon,
	FacebookIcon,
	TwitterIcon,
	TikTokIcon,
	LinkedInIcon,
	InstagramIcon
} from '../../svgs/Icons'

interface LockUpFullScreenProps {
	block: any
}

const LockUpFullScreen = (props: LockUpFullScreenProps) => {

	const { block } = props

	return (
		<div className={`
			lock-up-full-screen
			lock-up-full-screen--color-${block.backgroundColor ? block.backgroundColor : 'gray'}
			lock-up-full-screen--align-image-${block.imageAlign ? block.imageAlign : 'right'}
			lock-up-full-screen--padding-top-${block.removeSpaceAbove ? 'none' : 'standard'}
		`}>
			<div className={`lock-up-full-screen__inner`}>
				<div className={`lock-up-full-screen__media`}>
					<div className="lock-up-full-screen__media-wrapper">
						<ResponsiveImage
							image={block.image && block.image.length > 0 ? block.image[0] : null}
							cleanImplementation={true}
							containerClass={`lock-up-full-screen__media-container`}
						/>
					</div>
				</div>

				<div className="lock-up-full-screen__content">
					<div className="lock-up-full-screen__content-wrapper">
						<div className="lock-up-full-screen__content-container">
							{block.heading && <h2>{block.heading}</h2>}
							{block.text && <div dangerouslySetInnerHTML={{ __html: block.text }} />}

							<LinkButton
								link={block.linkTo}
								linkClass={`button button--${block.backgroundColor === 'blue' ? 'on-dark' : 'inverted'}`}
							/>							

							{block.socialMediaLinks?.length &&
								<ul className="social-media-link-list">
									{block.socialMediaLinks?.map((link: any, index: number) => {
											let icon = null;

											switch (link.icon) {
												case 'youtube':
													icon = <YoutubeIcon />
													break;
												case 'facebook':
													icon = <FacebookIcon />
													break;
												case 'twitter':
													icon = <TwitterIcon />
													break;
												case 'tiktok':
													icon = <TikTokIcon />
													break;
												case 'linkedin':
													icon = <LinkedInIcon />
													break;
												case 'instagram':
													icon = <InstagramIcon />
													break;
												default:
													icon = null;
											}
										return (
											<li key={`${block.id}-${index}`} className="social-media-link-list__item">
												<a href={link.linkUrl} target="_blank" rel="noopener noreferrer" className="social-media-link">{icon}</a>
											</li>
										)
									})}
								</ul>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}
export default LockUpFullScreen
