import React from 'react'
import { Bar } from 'react-chartjs-2'
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles'
import styled from '@emotion/styled'

const BarWrapper = styled('div')`
  height: 340px;
  width: 100%;
  max-width: 800px;

  canvas {
    height: 100% !important;
    width: 100% !important;
  }
`


const CreditQuality = ({ data }) => {
  const chartData = {
    labels: data[0].datums.map((datum) => datum.x),
    datasets: [
      {
        label: '',
        data: data[0].datums.map((datum) => datum.y),
        backgroundColor: '#037770'
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.dataset.data[context.dataIndex]
            return value ? `${value.toFixed(2)}%` : ''
          }
        }
      }
    }
  }

  return (
    <SectionWrapper>
      <SectionTitle>Credit quality</SectionTitle>
      <BarWrapper>
        <Bar height={340} data={chartData} options={options} />
      </BarWrapper>
    </SectionWrapper>
  )
}

export default CreditQuality
