import React, { useEffect, useState } from 'react';
import 'what-input';
import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import { Global } from '@emotion/react';
import { globalStyles } from './styled/GlobalStyles';
import App from './FundPerformanceContent';

const queryClient = new QueryClient();

const FundPerformanceApp = ( props ) => {
	const mode = props.mode;
	const { aamPortfolioCode, linkToPage, linkLabel } = props.block;

	const [fund, setFund] = useState(null);

	useEffect(() => {
		if ( aamPortfolioCode ) {
			setFund({ Portfolio_Code: aamPortfolioCode.toUpperCase(), locale: 'en' });
		}

		/* Clean up after component unmount */
		return () => {
			setFund({ Portfolio_Code: null });
    	};
	}, [ aamPortfolioCode ]);

	if (!fund) return null;

	return (
		<div className="fund-performance-app">
			<div className="fund-performance-app__wrapper">
				<div className="fund-performance-app__container">
					<Global styles={globalStyles} />
					<QueryClientProvider client={queryClient}>
						<App fund={fund} mode={mode} linkToPage={linkToPage} linkLabel={linkLabel} />
					</QueryClientProvider>
				</div>
			</div>
		</div>
	);
};

export default FundPerformanceApp;
