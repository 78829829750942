import Link from 'next/link';
import FundPerformanceApp from '../../fundPerformance/App/FundPerformanceApp'

interface KeyFiguresProps {
	block: any
}

const KeyFigures = (props: KeyFiguresProps) => {

	const { block } = props

	return (
		<div className={`key-figures`}>
			<div className="key-figures__wrapper">
				<div className="key-figures__container">
					<div className="key-figures__heading">
						{block.heading && <h2>{block.heading}</h2>}
						{block.description && <p>{block.description}</p>}
					</div>
					<FundPerformanceApp key={block.id} block={block} mode={'keyFigures'}/>
				</div>
				{block.showChart &&
					<div className="key-figures__chart-container">
						<FundPerformanceApp key={`key-figures-chart-${block.id}`} block={block} mode={'chart'}/>
					</div>
				}
			</div>
		</div>
	)

}
export default KeyFigures
