import React, { useContext, useEffect, useMemo, useState } from 'react'
import { curveLinear } from 'd3-shape'
import axios from 'axios'
import Chart from '../../shared/components/Chart'
import { chartColors } from '../../shared/styles'
import Select from '../../shared/components/Select'
import CustomTooltip from '../../shared/components/ChartTooltip'
import styled from '@emotion/styled'
import { CalendarIcon } from '../../shared/icons'
import { SectionTitle, SectionWrapper } from '../styled/GlobalStyles'
import ReturnTable from './ReturnTable'
import ReturnTableMonths from './ReturnTableMonths'
import { ClassContext } from '../FundPerformanceContent'
import { fetchNavPositions, fetchReturnChart } from '../../utils/services/api'

const SelectsWrapper = styled('div')`
  display: flex;
`

const ChartWrapper = styled('div')`
  margin-bottom: 60px;
`

const getDateOptions = (inceptionDate, rDate) => {
  const formattedDate = rDate;

  return [
    {
    label: 'This month',
    value: new Date(formattedDate.getFullYear(), formattedDate.getMonth(), 1).toISOString()
  },
   {
    label: 'This year',
    value: new Date(formattedDate.getFullYear() - 1, 11, 31).toISOString()
  }, {
    label: 'Last 3 months',
    value: new Date(new Date(formattedDate.valueOf()).setMonth(formattedDate.getMonth() - 3)).toISOString()
  },  {
    label: 'Last 6 months',
    value:  new Date(new Date(formattedDate.valueOf()).setMonth(formattedDate.getMonth() - 6)).toISOString()
  },  {
    label: 'Last 12 months',
    value: new Date(new Date(formattedDate.valueOf()).setMonth(formattedDate.getMonth() - 12)).toISOString()
  }, {
    label: 'Last 36 months',
    value: new Date(new Date(formattedDate.valueOf()).setMonth(formattedDate.getMonth() - 36)).toISOString()
  }, {
    label: 'Last 60 months',
    value: new Date(new Date(formattedDate.valueOf()).setMonth(formattedDate.getMonth() - 60)).toISOString()
  }, {
    label: 'Since inception',
    value: new Date(inceptionDate).toISOString()
  }]
}


const getReturnChartData = async (ISIN, date, name) => {
  const {data: dataNavPositions} = await fetchNavPositions({key: 'ISIN', value: ISIN, date, sort: 'rDate:DESC', limit: 1});

  const formattedDate = dataNavPositions?.[0]?.rDate ?  new Date(dataNavPositions[0].rDate) : date;

  const { data } = await fetchReturnChart({key: 'ISIN', value: ISIN, date: formattedDate, sort: 'rDate:ASC', limit: -1});

  return [{ ...data[0], label: name }]
}

const getBenchmarkChartData = async (benchmark, date) => {
  const { data } = await fetchReturnChart({key: 'name', value: benchmark.value, date: date, sort: 'rDate:ASC', limit: -1});

  return { ...data[0], label: benchmark.label }
}

const Return = () => {    
  const {
    ISIN,
    inceptionDate2: inceptionDate,
    benchmarks,
    name,
    benchmark
  } = useContext(ClassContext)

  const [rDate, setRDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBenchmarks, setSelectedBenchmarks] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchNavPositions({key: 'ISIN', value: ISIN, sort: 'rDate:DESC', limit: 1});

      if (data && data.length > 0) {
        setRDate(new window.Date(data[0].rDate));
      }
    };

    fetchData();
  }, [ISIN]);

  useEffect(() => {
    if (rDate) {
      const dateOptions = getDateOptions(inceptionDate, rDate);
      setOptions(dateOptions);
      setSelectedDate(dateOptions[4]);
    }
  }, [rDate, inceptionDate]);
  
  const [data, setData] = useState([])
  const [chartData, setChartData] = useState([])
  const [benchmarkData, setBenchmarkData] = useState([])

  useEffect(() => {
    if (selectedDate) {
      const newDate = options.find(option => option.label === selectedDate.label);
    
      if (newDate && newDate.value !== selectedDate.value) {
        setSelectedDate(newDate);
      }
    }
  }, [name]);

  useEffect(() => {
    const updateDateAndFetchData = async () => {

      if (!selectedDate) return;

      const getReturnData = async () => {
        return await getReturnChartData(ISIN, selectedDate.value, name);
      }
      const data = await getReturnData();

      setData(data);
      setChartData(data);
  
      if (benchmarks) {
        const getBenchmarkData = async () => {
          const promises = await benchmarks.map(async bm => await getBenchmarkChartData(bm, selectedDate.value));
          return Promise.all(promises);
        }
        const b = await getBenchmarkData();
        setBenchmarkData(b);
      }
  
      if (benchmark) {
        setSelectedBenchmarks([{ label: benchmark.name, value: benchmark.class }]);
      }
    };
  
    updateDateAndFetchData();
  }, [ISIN, benchmark, benchmarks, name, selectedDate]);

  useEffect(() => {
    const updateChartDataWithSelectedBenchmarks = () => {
      const filter = benchmarkData.filter(bms =>
        selectedBenchmarks.some(sBms => sBms.label === bms.label)
      );
      setChartData([...data, ...filter]);
    };

    updateChartDataWithSelectedBenchmarks();
  }, [selectedBenchmarks, benchmarkData, data]);


  const series = useMemo(
    () => ({
      showPoints: false,
      curve: curveLinear
    }),
    []
  )

  const axes = useMemo(
    () => [
      {
        primary: true,
        type: 'utc',
        position: 'bottom',
        showGrid: false
      },
      {
        type: 'linear',
        position: 'left',
        format: () => ''
      },
      {
        type: 'linear',
        position: 'right',
        format: value => `${value}%`
      }
    ],
    []
  )

  const tooltip = useMemo(
    () => ({
      render: ({ datum, secondaryAxis }) => (
        <CustomTooltip
          {...{
            secondaryAxis: {
              ...secondaryAxis,
              format: d => `${d.toFixed(2)}%`
            },
            datum,
            isDate: true
          }}
        />
      )
    }),
    []
  )

  if (data.length === 0 || !selectedDate || options?.length < 1) return;

  return (
    <SectionWrapper>
      <SectionTitle>Return</SectionTitle>
      <SelectsWrapper>
        <CalendarIcon />
        <Select
          options={options}
          isSearchable={false}
          hideSelectedOptions
          width={175}
          value={selectedDate}
          onChange={date => setSelectedDate(date)}
        />
        <Select
          options={benchmarks}
          isSearchable={false}
          isMulti
          hideSelectedOptions={false}
          value={selectedBenchmarks}
          onChange={(value) => setSelectedBenchmarks(value)}
        />
      </SelectsWrapper>
      <ChartWrapper>
        <Chart
          colors={chartColors}
          data={chartData}
          axes={axes}
          series={series}
          showCrosshair
          tooltip={tooltip}
        />
      </ChartWrapper>
      <ReturnTable />
      <ReturnTableMonths />
    </SectionWrapper>
  )
}

export default Return
