import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Spinner from '../../shared/components/Spinner'
import { GreenColor } from '../../shared/styles'
import Growth from '../../shared/components/Growth'
import { ClassContext } from '../FundPerformanceContent'

const useNavPositions = (isin, inceptionDate) => useQuery(['panels', isin], async () => {
  const { data } = await axios.get(`/api/funds/nav-positions`, {
    params: {
      key: 'ISIN',
      value: isin,
      sort: 'rDate:DESC',
      limit: 1
    }
  })

  if (data[0]) {
    const yearToDate = new window.Date(new window.Date(data[0].rDate).getFullYear() - 1, 11, 31)
    const iDate = new Date(inceptionDate)

    let date = yearToDate
    let header = 'Year to Date'
    if (iDate > yearToDate) {
      date = iDate
      header = 'Since inception'
    }

    const { data: lastYear } = await axios.get(`/api/funds/nav-positions`, {
      params: {
        key: 'ISIN',
        value: isin,
        sort: 'rDate:DESC',
        date: date,
        limit: 1
      }
    })

    if (lastYear && lastYear[0]) {
      return {
        ...data[0],
        growth: {
          header: header,
          value: (((data[0].NAV / lastYear[0].NAV) - 1) * 100).toFixed(2)
        }
      }
    }

    return {
      ...data[0],
      growth: {
        header: '',
        value: null
      }
    }
  }

  return {}
})

const Panels = () => {
  const { ISIN, locale, inceptionDate2: inceptionDate } = useContext(ClassContext)
  const { data, status, error } = useNavPositions(ISIN, inceptionDate)

  if (status === 'loading') return <Spinner />
  if (status === 'error') {
    return (
      <span>Error: {error.message}</span>
    )
  }

  if (!data) return null

  const date = new window.Date(data.rDate)
    .toLocaleDateString(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })

  return (
    <Container>
      <Panel>
        <Value>
          {data.NAV || '-'}
          <Caption>NAV</Caption>
        </Value>
      </Panel>
      <Panel>
        <StyledDateDiv>{date || '-'}</StyledDateDiv>
      </Panel>
      <Panel>
        <StyledGrowthDiv>
          <Growth percent={data?.growth?.value} />
          <Caption>{data?.growth?.header ?? 'Year to Date'}</Caption>
        </StyledGrowthDiv>
      </Panel>
      {
        <Panel>
          <StyledGrowthDiv>
            <Growth percent={data?.pa12M} />
            <Caption>Last 12 months</Caption>
          </StyledGrowthDiv>
        </Panel>
      }
    </Container>
  )
}

export default Panels

const Container = styled('div')`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 92px 92px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  line-height: 1.1;
  margin-bottom: 60px;


  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 128px;
    grid-gap: 20px;
  }
`

const Panel = styled('div')`
  border: 1px solid ${transparentize(0.9, '#2E5082')};
  font-size: 28px;
  padding: 10px 10px;
  height: 100%;
  justify-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-sizing: border-box;

  span svg {
    height: 24px;
    width: 24px;
    margin-left: 10px;
  }
`

const Caption = styled('div')`
  font-size: 16px;
  color: #7A7A7A;
  margin: 8px 0 0 0;
  text-align: center;
`

const Value = styled('div')`
  display: flex;
  flex-direction: column;
`

const StyledDateDiv = styled('div')`
  text-align: center;
`

const StyledGrowthDiv = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${GreenColor};
`
