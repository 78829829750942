import Link from 'next/link'

import ResponsiveImage from '../../../common/ResponsiveImage'

interface ManagementListProps {
	block: any
}

const ManagementList = (props: ManagementListProps) => {

	const { block } = props

	if (!block.selectedPeople || block.selectedPeople.length === 0) {
		return null
	}

	return (
		<div key={block.id} className="management-list">
			<div className="management-list__wrapper">
				{block.heading &&
					<div className="management-list__heading-wrapper">
						<div className="management-list__heading">
							<h2>{block.heading}</h2>
						</div>
					</div>
				}
				{block.selectedPeople.map((person: any, index: number) => {

					const image = person.image && person.image.length > 0 ? person.image[0] : null

					return (
						<div
							key={person.id}
							className={`management-list__item management-list__item--image-align-${index % 2 === 0 ? 'right' : 'left'}`}
						>
							<div className="management-list__item-inner">
								<div className="management-list__item-media">
									{image ? (
										<ResponsiveImage
											image={image}
											containerClass={`management-list__item-media-container`}
											cleanImplementation={true}
										/>
									) : (
										<div className="management-list__item-media-placeholder">
											<div className="management-list__item-media-placeholder-inner" />
										</div>
									)}

								</div>
								<div className="management-list__item-content">
									<h3>{person.title}</h3>
									<p>{person.workTitle}</p>
									<div dangerouslySetInnerHTML={{__html: person.shortBio}} />
									<ul className="management-list__contact-list">
										{person.email &&
											<li className="management-list__contact-list-item">
												<span className="management-list__contact-list-label">Email</span>
												<a href={`mailto:${person.email.replaceAll(' ', '')}`}>{person.email}</a>
											</li>
										}
										{person.phone &&
											<li className="management-list__contact-list-item">
												<span className="management-list__contact-list-label">Phone</span>
												<a href={`tel:${person.phone.replace('+', '00').replaceAll(' ', '')}`}>{person.phone}</a>
											</li>
										}
									</ul>
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)

}
export default ManagementList
