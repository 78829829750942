import { SubTextColor } from '../../../shared/styles';
import { css } from '@emotion/react';
import { transparentize } from 'polished';

// eslint-disable-next-line import/prefer-default-export
export const scrollbarStyles = css`
  overflow: auto;

  scrollbar-color: ${transparentize(0.9, SubTextColor)};
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background-color: ${transparentize(0.9, SubTextColor)};
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 5px;
  }

  &:hover::-webkit-scrollbar {
    height: 10px;
  }
`;
