import Link from 'next/link'
import LinkByType from '../../common/LinkByType'
import { 
	DownloadIcon, 
	PdfIcon,
	PptIcon,
	ZipIcon,
	PngIcon,
	XlsIcon,
	JpgIcon,
	DocIcon,
	LinkIcon
} from '../../svgs/Icons'

import ContentContainer from '../ContentContainer'

interface DownloadsListProps {
	block: any
	section?: string
	downloadClick?: any
	addBottomSpacing?: boolean
}

const DownloadsList = (props: DownloadsListProps) => {

	const { block, section, downloadClick, addBottomSpacing } = props

	if (!block || !block.documents || block.documents.length === 0) {
		return null
	}

	const showDisclaimer = () => {
		downloadClick();
	}
	
	let listClass = "";

	if (addBottomSpacing) {
		listClass = "bottom-spacing";
	}

	return (
		<ContentContainer
			heading={block.heading}
			link={block.linkTo?.url ? <LinkByType link={block.linkTo} linkClass="button button--inverted" /> : null}
		>
			<div className={`downloads-list ${listClass}`}>
				<div className="downloads-list__item">
					<div className="downloads-list__item-inner">
						<div className="downloads-list__item-type">Type</div>
						<div className="downloads-list__item-title">Title</div>
						<div className="downloads-list__item-link"></div>
					</div>
				</div>

				{block.documents.map((document: any) => {

					{/*const type = document.mimeType.split('/')[1]*/}
					const type = document.extension;					
					let icon = null;

					switch (type) {
						case 'pdf':
							icon = <PdfIcon />
							break;
						case 'ppt':
						case 'pptx':
							icon = <PptIcon />
							break;
						case 'zip':
							icon = <ZipIcon />
							break;
						case 'png':
							icon = <PngIcon />
							break;
						case 'xls':
						case 'xlsx':
							icon = <XlsIcon />
							break;
						case 'jpg':
						case 'jpeg':
							icon = <JpgIcon />
							break;
						case 'doc':
						case 'docx':
							icon = <DocIcon />
							break;
						default:
							// @TODO default icon
	    					console.log(`Unknown file type`);
					}
					return (
						<div key={document.id} className="downloads-list__item">
							<div className="downloads-list__item-inner">
								<div className="downloads-list__item-type">
									{icon}
								</div>
								<div className="downloads-list__item-title">{document.title}</div>
								<div className="downloads-list__item-link">
									{section == 'offering' ?
										<button
											onClick={showDisclaimer}
											className="button button--inverted"
											><DownloadIcon /> <span className="button-label">Download</span>
										</button>
									: <Link
										href={document.url}
										target="_blank"
										rel="noopener noreferrer"
										className="button button--inverted"
										><DownloadIcon /> <span className="button-label">Download</span></Link>
									}
								</div>
							</div>
						</div>
					)
				})}

				{block.externalLinks && block.externalLinks.map((link: any, index: number) => {
					return (
						<div key={`${block.id}-${index}`} className="downloads-list__item">
							<div className="downloads-list__item-inner">
								<div className="downloads-list__item-type">
									<LinkIcon />
								</div>
								<div className="downloads-list__item-title">{link.linkLabel ? link.linkLabel : link.linkUrl}</div>
								<div className="downloads-list__item-link">
									<Link
										href={link.linkUrl}
										target="_blank"
										rel="noopener noreferrer"
										className="button button--inverted"
										><span className="button-label">Open</span>
									</Link>
									
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</ContentContainer>
	)

}
export default DownloadsList
