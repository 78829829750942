/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import RSelect, { components } from 'react-select'
import { BenchmarksIcon, CheckIcon } from '../icons'
import { FundColor, MainTextColor } from '../styles'
import styled from '@emotion/styled'
import { scrollbarStyles } from './styled/ScrollBar'

const SelectWrapper = styled('div')`
  margin-bottom: 15px;
  margin-right: 15px;
  width: ${({ width }) => width}px;
`

const StyledMenuList = styled('div')`

  & > div {
    ${scrollbarStyles};
  }
`

const OptionWrapper = styled('div')`
  position: relative;
  padding-right: 20px;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-7px);
    width: 15px;
    height: 15px;
  }
`

const MenuList = props => {
  return (
    <StyledMenuList>
      <components.MenuList {...props}>
        {props.children}
      </components.MenuList>
    </StyledMenuList>
  )
}

const Option = ({ children, isSelected, ...props }) => (
  <components.Option {...props}>
    <OptionWrapper isSelected={isSelected}>
      {children} {isSelected ? <CheckIcon /> : null}
    </OptionWrapper>
  </components.Option>
)

Option.propTypes = {
  children: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired
}

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <BenchmarksIcon />
    {children}
  </components.Control>
)

Control.propTypes = {
  children: PropTypes.node.isRequired
}

const customStyles = {
  option: base => ({
    ...base,
    backgroundColor: 'transparent',
    color: MainTextColor,

    ':hover': {
      backgroundColor: '#edebeb'
    }
  }),
  menu: base => ({
    ...base,
    minWidth: 175
  }),
  control: base => ({
    ...base,
    border: 'none',
    color: FundColor,
    outline: 'none',
    boxShadow: 'none',
    minHeight: 'auto',

    ':hover': {
      border: 'none'
    }
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: base => ({
    ...base,
    color: FundColor,

    svg: {
      width: '15px',
      height: '15px'
    }
  }),
  valueContainer: base => ({
    ...base,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }),
  singleValue: base => ({
    ...base,
    color: FundColor
  }),
  multiValue: base => ({
    ...base,
    color: FundColor
  })
}

const MultiValueContainer = (props) => {
  return (
    <components.MultiValueContainer {...props} />
  )
}

const Select = ({
  options,
  isMulti,
  width,
  ...props
}) => {
  if (isMulti) {
    return (
      <SelectWrapper width={width}>
        <RSelect
          options={options}
          styles={customStyles}
          isMulti
          isClearable={false}
          components={{ MultiValueContainer, Control, Option, MenuList }}
          {...props}
        />
      </SelectWrapper>
    )
  }

  return (
    <SelectWrapper width={width}>
      <RSelect
        options={options}
        styles={customStyles}
        {...props}
      />
    </SelectWrapper>
  )
}

Select.propTypes = {
  isMulti: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }))
}

Select.defaultProps = {
  isMulti: false,
  width: 300
}

export default Select
