import Link from 'next/link'

interface SectorNavigationListProps {
	block: any
}

/**
 * Component for showing services in a list
 *
 * @component
 */
const SectorNavigationList = (props: SectorNavigationListProps) => {

	const { block } = props

	// TODO - filter out current service if displaying sibling services.
	if ( !block.relatedSectors || block.relatedSectors.length === 0 ) {
		return null
	}

	return (
		<section className="content-container">
			{block.heading ? (
				<header className="content-container__header-wrapper">
					<div className="content-container__header-title">
						<h2>{block.heading}</h2>
					</div>
				</header>
			) : null}
			<ul className="three-column-list">
				{block.relatedSectors.map(( sector: any ) => {
					return (
						<li key={sector.id} className="three-column-list__item">
							<article className="product-card">
								<h3 className="product-card__title">{sector.title}</h3>
								{sector.shortDescription && <div className="product-card__body" dangerouslySetInnerHTML={{__html: sector.shortDescription}} />}
								<p><Link href={`/${sector.uri}`}>Read more →</Link></p>
							</article>
						</li>
					)
				})}
			</ul>
		</section>
	)

}
export default SectorNavigationList
