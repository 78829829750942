import { BlockList } from "net"
import ResponsiveImage from "../../common/ResponsiveImage"

interface ImageProps {
	block: any
	size?: string
}

const Image = (props: ImageProps) => {

	const { block, size } = props

	return (
		<div className={`block-single-media block-single-media--size--${size ? size : 'default'}`}>
			<div className="block-single-media__wrapper">
				<div className="block-single-media__content">
					<ResponsiveImage image={ block.image && block.image.length > 0 ? block.image[0] : null } />
				</div>
			</div>
		</div>
	)

}
export default Image
