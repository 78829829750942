import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useResizeDetector } from 'react-resize-detector';
import { ChevronRight } from '../../../shared/icons';
import { FundColor } from '../../../shared/styles';

const ClassTabs = ({
	tabNames, activeTab, scrollStep, changeActiveTab,
}) => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [isScrollable, setIsScrollable] = useState(false);
	const [isScrollableLeft, setIsScrollableLeft] = useState(false);
	const [isScrollableRight, setIsScrollableRight] = useState(true);

	const {
		width: scrollContainerWidth,
		ref: refScrollContainer,
	} = useResizeDetector();
	const {
		width: tabsContainerWidth,
		ref: refTabsContainer,
	} = useResizeDetector();

	useEffect(() => {
		if (scrollPosition === 0) {
			setIsScrollableLeft(false);
		} else {
			setIsScrollableLeft(true);
		}

		const canScrollRight = (Math.ceil(tabsContainerWidth) -
			Math.ceil(scrollContainerWidth)) === Math.floor(scrollPosition);
		if (canScrollRight) {
			setIsScrollableRight(false);
		} else {
			setIsScrollableRight(true);
		}
	}, [scrollContainerWidth, scrollPosition, tabsContainerWidth]);

	useEffect(() => {
		setIsScrollable(scrollContainerWidth < tabsContainerWidth);
	}, [scrollContainerWidth, tabsContainerWidth]);

	const onScroll = () => {
		const newScrollPosition = refScrollContainer.current.scrollLeft;
		setScrollPosition(newScrollPosition);
	};

	const handleScrollRight = () => {
		refScrollContainer.current.scrollLeft += scrollStep;
	};
	const handleScrollLeft = () => {
		refScrollContainer.current.scrollLeft -= scrollStep;
	};

	const handleChangeActiveTab = name => () => {
		changeActiveTab(name);
	};

	return (
		<Wrapper>
			{(isScrollable && isScrollableLeft) &&
				<ArrowContainerLeft>
					<ScrollButton onClick={handleScrollLeft}>
						<ChevronLeft fill={FundColor} />
					</ScrollButton>
				</ArrowContainerLeft>
			}
			<ScrollContainer ref={refScrollContainer} onScroll={onScroll}>
				<ScrollableTabsWrapper
					changeActiveTab={changeActiveTab}
					ref={refTabsContainer}
				>
					{tabNames && tabNames.map((name, index) => (
						<Tab
							key={`classTabKey-${index + 1}`}
							active={name === activeTab}
							onClick={handleChangeActiveTab(name)}
						>
							{name}
						</Tab>
					))}
				</ScrollableTabsWrapper>
			</ScrollContainer>
			{(isScrollable && isScrollableRight) &&
				<ArrowContainerRight>
					<ScrollButton onClick={handleScrollRight}>
						<ChevronRight fill={FundColor} />
					</ScrollButton>
				</ArrowContainerRight>
			}
		</Wrapper>
	);
};

ClassTabs.propTypes = {
	tabNames: PropTypes.arrayOf(PropTypes.string),
	activeTab: PropTypes.string,
	scrollStep: PropTypes.number,
	changeActiveTab: PropTypes.func,
};

ClassTabs.defaultProps = {
	scrollStep: 120,
};

export default ClassTabs;


const Wrapper = styled('div')`
  display: flex;
  position: relative;
  margin-bottom: 36px;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    position: absolute;
    bottom: 0;
  }
`;

const ScrollContainer = styled('div')`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;

  /* styles for scroll hiding */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollableTabsWrapper = styled('div')`
  display: flex;
`;

const Tab = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7A7A7A;
  min-width: 120px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.4s;

  &:hover {
    background-color: #EEEEEE;
  }

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    transition: 0.3s;
    background-color: ${props => (props.active ? FundColor : 'transparent')};
    position: absolute;
    z-index: 1;
    bottom: 0;
  }
`;

const ChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
`;

const ArrowContainerLeft = styled('div')`
  position: absolute;
  height: calc(100% - 1px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 10px;
  z-index: 10;
  background-image: linear-gradient(to right, white , transparent);
`;

const ArrowContainerRight = styled(ArrowContainerLeft)`
  right: 0;
  padding: 0 10px 0 20px;
  background-image: linear-gradient(to left, white , transparent);
`;

const ScrollButton = styled('button')`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-top: 4px;
  position: relative;
`;
