import React, { useContext } from 'react';
import Table from '../../shared/components/Table';
import { ClassContext } from '../FundPerformanceContent';

const addPercentSymbol = value => (value ? `${value}%` : '-');

const columns = [
  {
    title: 'ISIN',
    key: 'ISIN',
    index: 'ISIN',
  }, {
    title: 'Ticker',
    key: 'ticker',
    index: 'ticker',
  }, {
    title: 'Start date',
    key: 'inceptionDate2',
    index: 'inceptionDate2',
    render: string => new Date(string).toLocaleDateString(),
  }, {
    title: 'Annual management fee',
    key: 'mFee',
    index: 'mFee',
    render: addPercentSymbol,
  }, {
    title: 'Performance fee',
    key: 'pFee',
    index: 'pFee',
    render: addPercentSymbol,
  }, {
    title: 'Min. investment',
    key: 'minInvest',
    index: 'minInvest',
    render: string => Number(string).toLocaleString('nb-NO'),
  },
];

const Return = () => {
  const activeClass = useContext(ClassContext);

  return (
    <Table rows={[activeClass]} columns={columns} isVertical />
  );
};

export default Return;
