import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import ClassTabs from './ClassTabs';
import { ClassContext } from '../../FundPerformanceContent';

const ClassTabsWrapper = ({ setActiveTab }) => {
  const activeClass = useContext(ClassContext);
  const queryClient = useQueryClient();
  const classes = queryClient.getQueryData(['classes']);
  const tabNames = classes ? classes.map(c => c.class) : null;

  return (
    <ClassTabs
      tabNames={tabNames}
      activeTab={activeClass.class}
      changeActiveTab={name => setActiveTab(name)}
    />
  );
};

ClassTabsWrapper.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
};

export default ClassTabsWrapper;
