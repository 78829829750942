import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { LegendItem, LegendLabel } from './styled/Legend';
import { TooltipContent } from './styled/Tooltip';
import { ClassContext } from '../../App/FundPerformanceContent';

const DateValue = styled('p')`
  font-size: 14px;
  margin-bottom: 12px;
  margin-top: 0;
  font-weight: bold;
  text-align: center;
`;

const ChartTooltip = ({ secondaryAxis, datum, isDate }) => {
  const { locale } = useContext(ClassContext);

  return (datum ? (
    <TooltipContent>
      {isDate && (
        <DateValue>
          {new window.Date(datum.primary).toLocaleDateString(locale)}
        </DateValue>
      )}
      {datum.group.map(item => (
        <LegendItem tooltip key={item.series.seriesLabel}>
          <LegendLabel color={item.series.style.color} />
          {secondaryAxis.format(item.yValue)}
        </LegendItem>
      ))}
    </TooltipContent>
  ) : null);
};

ChartTooltip.propTypes = {
  secondaryAxis: PropTypes.shape({
    format: PropTypes.func,
  }).isRequired,
  datum: PropTypes.shape({
    group: PropTypes.arrayOf(PropTypes.shape({
      yValue: PropTypes.number,
      series: PropTypes.shape({
        style: PropTypes.shape(),
        seriesLabel: PropTypes.string,
      }),
    })),
    primary: PropTypes.string,
  }),
  isDate: PropTypes.bool,
};

ChartTooltip.defaultProps = {
  datum: null,
  isDate: false,
};

export default ChartTooltip;
