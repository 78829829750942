import styled from '@emotion/styled';

export const Container = styled('div')`
  width: 100%;
`;

export const Col = styled('div')`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  width: 100%;
  padding: 5px 10px;

  @media (min-width: 768px) {
    padding: 10px 15px;
  }

  @media (min-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  ${({ half }) => half && `
    flex: 0 0 auto;
    width: 100%;

    @media (min-width: 576px) {
      width: 50%;
    }
  `};
`;

export const Row = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => (justify || 'flex-start')};
  align-items: ${({ align }) => (align || 'normal')};
  margin-left: -5px;
  margin-right: -5px;

  @media (min-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
  }

  @media (min-width: 1200px) {
    margin-left: -20px;
    margin-right: -20px;
  }
`;
