import { useState } from 'react'

import Link from 'next/link'
import { ChevronDown } from '../../svgs/Icons'

import LinkByType from '../../common/LinkByType'

interface BlockLinkListProps {
	block: any
}

const BlockLinkList = (props: BlockLinkListProps) => {

	const { block } = props

	return (
		<section className='block-link-list'>
			<div className='block-link-list__wrapper'>
				<div className='block-link-list__content'>
					<ul className='link-list'>
						{block.links.map((link: any) => {

							const linkTo = link.linkTo
							if (linkTo && linkTo.url) {
								return (
									<li key={link.id} className='link-list__item'>
										<LinkByType link={linkTo} linkClass={`link-list__link`} addArrowRight={true} />
									</li>
								)
							}

						})}
					</ul>
				</div>
			</div>
		</section>
	)

}

export default BlockLinkList
