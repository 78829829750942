import React, { Fragment, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Growth from '../../shared/components/Growth';
import Spinner from '../../shared/components/Spinner';
import Table from '../../shared/components/Table';
import { SectionTitle } from '../styled/GlobalStyles';
import { ClassContext } from '../FundPerformanceContent';

const columns = [{
  title: 'Company',
  key: 'Name',
  index: 'Name',
}, {
  title: 'Attribution',
  key: 'Attribution',
  index: 'Attribution',
  render: data => <Growth percent={data} />,
}];

const sortFunc = (negative = false) => {
  return (a, b) => {
    if(!negative && a.Attribution < b.Attribution) return 1;
    if(negative && a.Attribution < b.Attribution) return -1;
    if(!negative && a.Attribution > b.Attribution) return -1;
    if(negative && a.Attribution > b.Attribution) return 1;

    return 0;
  };
};

const useAttributions = portfolioCode => useQuery(['attributions'], async () => {
	const { data: top } = await axios.get(`/api/funds/attributes`, {
		params: {
			portfolioCode: portfolioCode,
			period: 'YTD',
			topBot: 'top',
			sort: 'Attribution:DESC',
			limit: 5
		}
	});
	const { data: bottom } = await axios.get(`/api/funds/attributes`, {
		params: {
			portfolioCode: portfolioCode,
			period: 'YTD',
			topBot: 'bottom',
			sort: 'Attribution:DESC',
			limit: 5
		}
	});

	return [
    ...top.sort(sortFunc()),
    ...bottom.sort(sortFunc(true)),
  ];
});


const AttributionYear = () => {
  const { portfolioCode } = useContext(ClassContext);
  const { isLoading, isSuccess, data } = useAttributions(portfolioCode);

  return (
    <Fragment>
      <SectionTitle>Attribution year to date</SectionTitle>
      {isLoading && <Spinner />}
      {isSuccess && <Table rows={data} columns={columns} />}
    </Fragment>
  );
};

export default AttributionYear;
