import styled from '@emotion/styled';
import {
  LightGrayColor,
  PaleGrayColor,
  SubTextColor,
  FundColor,
  MainTextColor,
} from '../../styles';

// eslint-disable-next-line import/prefer-default-export
export const StyledTable = styled('table')`
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  border-top: 1px solid ${FundColor};
  
  thead {
    border: none !important;
  }
  
  ${({ hideOnMobile }) => hideOnMobile && `
  display: none;

    @media (min-width: 800px) {
      display: table;
    }
  `}

  ${({ isVertical }) => isVertical && `
  display: table;


    @media (min-width: 800px) {
      display: none;
    }
  `}

  thead tr {
    background-color: ${LightGrayColor};
  }

  tbody tr {
    background: transparent !important;
  }

  td {
    padding-top: ${({ isVertical }) => (isVertical ? 16 : 20)}px;
    padding-bottom: ${({ isVertical }) => (isVertical ? 16 : 20)}px;
    border-bottom: 1px solid ${PaleGrayColor};
    color: ${MainTextColor};
  }

  th {
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${SubTextColor};
    font-weight: 400;
    text-align: left;
  }
  
  td,
  th {
    padding-left: 8px;
    padding-right: 8px;
  }

  td:first-of-type,
  th:first-of-type {
    padding-left: 20px;
  }

  th:last-of-type,
  td:last-of-type {
    padding-right: 20px;
  }

  ${({ isVertical }) => isVertical && `
    tr td:first-of-type {
      color: ${SubTextColor};
    }
    
    tr:first-of-type td {
      background-color: ${LightGrayColor};
    }
  `};
`;
